<template>
  <div class="center">
    <preloader class="mb-5" />
    <div>
      <span v-html="$t('live.examLoading.questionLoading')" />
      <div
        v-if="showRetry"
        class="mt-4"
      >
        <div>
          {{ $t('live.examLoading.longerThanExpected') }}
        </div>
        <eva-button
          class="mt-4"
          primary
          @click="retry"
          :label="$t('live.examLoading.retry')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Preloader from "../../../components/shared/preloader.vue"

export default {
  name: "ExamLoading",
  components: { Preloader },
  props: {
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
    showRetry: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["retry"],
  methods: {
    retry() { this.$emit("retry") },
  },
}
</script>
