import { defaultWindow, useEventListener, useMouse } from "@vueuse/core"
import { ref, watch } from "vue"

/**
 * Reactive mouseleave event.
 *
 * @returns {Object} hasLeft, x, y
 */
export function useMouseLeave() {
  const window = defaultWindow

  const { x, y, sourceType } = useMouse()

  const hasLeft = ref(false)


  useEventListener(window.document, "mouseleave", () => {
    // Only update if the source is the mouse
    // If the source is touch, the mouseleave event is not reliable
    // as it will trigger when the touch starts.
    // i.e.: on iPad, when the user touches the screen, the mouseleave
    // event is triggered if a mouse is connected.
    if (sourceType.value === "mouse") hasLeft.value = true
  } )

  useEventListener(window.document, "mouseenter", () => {
    hasLeft.value = false
  })

  watch( [x, y], () => { hasLeft.value = false })

  return { hasLeft, x, y }
}
