<template>
  <div :class="classes">
    <evalmee-chip
      v-if="currentStatus.correctionTag"
      color="green"
      class="mr-2"
      label
      small
    >
      {{ $t('activities.correction') }}
    </evalmee-chip>
    <evalmee-chip
      v-if="quiz"
      small
      :color="currentStatus.color"
      label
    >
      {{ currentStatus.label }}
    </evalmee-chip>
  </div>
</template>

<script>
import EvalmeeChip from "../shared/evalmee_chip.vue"
import quizScoreStudentMixin from "../../views/quizzes/quiz_score_student_mixin"

export default {
  name: "QuizStateBadgeStudent",
  components: { EvalmeeChip },
  props: {
    quiz: { type: Object, required: true },
    score: { type: Object, default: null },
    classes: { type: String, default: "ma-2" },
  },
  mixins: [quizScoreStudentMixin],
  computed: {
    currentStatus() {
      return {
        toAnswer: {
          label: this.$t("activities.toAnswer"),
          color: "red",
          correctionTag: false,
        },
        answered: {
          label: this.$t("activities.answered"),
          color: "green",
          correctionTag: this.quiz.whole_correction_visible,
        },
        closed: {
          label: this.$t("activities.closed"),
          color: "red",
          correctionTag: false,
        },
      }[this.currentStatusName]
    },
    currentStatusName() {
      if( this.isAnswered) return "answered"
      if( this.isClosed ) return "closed"

      return "toAnswer"
    },
  },

}
</script>

<style scoped>

</style>
