<template>
  <v-app-bar
    app
    class="app_bar_contained"
    scroll-target="#scroll_content"
    elevate-on-scroll
    color="#F8FBFF"
  >
    <logo-home-student
      :school="school"
      class="pl-2"
    />

    <v-spacer />

    <v-btn
      text
      class="text-none"
      href="/courses"
      v-if="currentUser.teacher"
    >
      <v-icon left>
        mdi-human-male-board
      </v-icon>
      {{ $t("evalmee.home.student.backToTeacherInterface") }}
    </v-btn>

    <user-menu
      :user="currentUser"
      :student="false"
    />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
import UserMenu from "../../components/app/user_menu.vue"
import LogoHomeStudent from "../../components/app/logo_home_student.vue"

export default {
  name: "EvalmeeHomeNav",
  components: { UserMenu, LogoHomeStudent },
  computed: {
    ...mapGetters(["currentUser", "school"]),
  },
}
</script>
