<template>
  <v-app id="evalmee-app">
    <v-container
      bg
      fill-height
      grid-list-md
      text-xs-center
    >
      <v-layout
        row
        wrap
        align-center
      >
        <v-flex>
          <v-row
            justify="center"
            class="center align-content-center flex-wrap"
          >
            <v-col
              class="text-center mb-16"
              cols="12"
            >
              <img
                :src="logoSrc"
                alt="Evalmee logo"
                class="logo"
                width="300"
              >
            </v-col>
            <router-view v-bind="$attrs" />
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import currentAppMixin from "../../components/shared/current_app_mixin"
import I18n from "../../plugins/i18n"

export default {
  name: "UserProfileContainer",
  mixins: [currentAppMixin],

  mounted() {
    I18n.locale = navigator.language.substring(0, 2)
  },
}
</script>

<style scoped>

</style>
