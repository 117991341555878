import Bowser from "bowser"

const browserDetection = {
  browserName() {
    return Bowser.parse(window.navigator.userAgent)?.browser?.name
  },

  isChrome() {
    return this.browserName() === "Chrome"
  },

  isChromeLike() {
    return ["Chrome", "Chromium"].includes(this.browserName())
  },

  isFirefox() {
    return this.browserName() === "Firefox"
  },

  isSafari() {
    return this.browserName() === "Safari"
  },

  // not optimal but 🤷‍
  mainBrowserMapping() {
    if (this.isChromeLike()) return "chrome"
    if (this.isFirefox())    return "firefox"
    if (this.isSafari())     return "safari"
    return "other"
  },

}

export default browserDetection
