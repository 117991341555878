<template>
  <div>
    <v-text-field
      label="Count"
      dense
      outlined
      type="number"
      size="10"
      v-model="count"
    />
    <v-text-field
      label="Search"
      dense
      outlined
      v-model="search"
    >
      <template #append>
        <v-btn
          @click="loadImages"
          depressed
          small
          color="primary"
        >
          Load
        </v-btn>
      </template>
    </v-text-field>

    <v-divider />

    References: {{ embeddingReferences.length }}


    <v-btn
      x-small
      depressed
      @click="embeddingReferences = []"
      v-html="'Clear'"
    />

    <div class="d-inline">
      <v-text-field
        dense
        outlined
        v-model="distanceThresholds.euclidean.max"
        label="euclidean max"
      />
      <v-text-field
        dense
        outlined
        v-model="distanceThresholds.cosine.min"
        label="cosine min"
      />
    </div>


    <v-card
      v-for="image in images"
      :key="image.id"
      class="mt-2"
    >
      <proctoringv2-debug-image
        :image="image"
        :embedding-references="embeddingReferences"
        :distance-thresholds="distanceThresholds"
        @set-embedding="(e) => embeddingReferences.push(e)"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Proctoringv2DebugImage from "./Proctoringv2DebugImage.vue"

export default {
  name: "Proctoringv2Debug",
  components: { Proctoringv2DebugImage },
  data: () => ({
    count: 10,
    search: "",
    embeddingReferences: [],
    distanceThresholds: {
      euclidean: {
        max: 0.8,
      },
      cosine: {
        min: 0.8,
      },
    },
  }),
  computed: {
    ...mapGetters(["proctoringImagesAnalysesV2"]),
    images() {
      return this.proctoringImagesAnalysesV2 || []
    },
  },
  methods: {
    ...mapActions(["fetchProctoringImagesAnalysisV2"]),
    loadImages() {
      return this.fetchProctoringImagesAnalysisV2({ limit: this.count, search: this.search })
    },
  },
  mounted() {
    this.loadImages()
  },
}
</script>

