<!--
 The v-select menu in not attached to v-app to ensure what the question drawer
is not closed when the select manu is open.

As the v-select menu is attached inside a nav we have to ensure it does not inherit from the nav styles
-->

<template>
  <div id="questions-filter-container">
    <v-select
      dense
      filled
      flat
      hide-details
      no-filter
      solo
      :items="items"
      item-value="name"
      v-model="selected"
      class="questions-filter very-dense"
      attach="#questions-filter-container"
    >
      <template #item="{item}">
        <v-list-item-icon class="mr-2">
          <v-icon
            v-text="item.icon"
            small
            :color="item.color"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.label" />
        </v-list-item-content>
        <v-list-item-action-text v-text="item.count" />
      </template>

      <template #selection="{item}">
        <v-list-item-icon class="mr-2">
          <v-icon
            v-text="item.icon"
            small
            :color="item.color"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            v-text="item.label"
            class="subtitle-2"
          />
        </v-list-item-content>
        <v-list-item-action-text v-text="item.count" />
      </template>
    </v-select>
  </div>
</template>

<script>
import { states } from "./exerciseStateHelpers"

export default {
  name: "QuestionsFilter",
  props: {
    value: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    items() {
      return [
        {
          name: "all",
          label: this.$t("live.questionDrawer.filter.states.all"),
          count: this.questions.filter((q) => q.isAnswerable).length,
        },
        {
          name: "unanswered",
          label: this.$t("live.questionDrawer.filter.states.unAnswered"),
          count: this.questions.filter((q) => !q.isAnswered && q.isAnswerable).length,
        },
        {
          name: "answered",
          label: this.$t("live.questionDrawer.filter.states.answered"),
          count: this.questions.filter((q) => q.isAnswered && q.isAnswerable).length,
        },
      ].map((item) => ({
        ...item,
        ...states.find((s) => s.name === item.name),
      }))
    },
  },
}

</script>


<style scoped>
.questions-filter::v-deep input {
  display: none;
}

#questions-filter-container::v-deep .v-list-item {
  line-height: 40px;
}
</style>
