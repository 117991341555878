<template>
  <div>
    <div>
      <div
        v-if="showLanguageSwitch"
        class="center"
      >
        <language-switch
          v-if="currentUser"
          v-model="currentUser.language"
          @change="saveStudent"
        />
      </div>

      <v-list
        color="transparent"
        class="ml-n6"
        :dense="dense"
      >
        <v-list-item
          v-for="(instruction, index) in instructions"
          :key="index"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>{{ instruction.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ instruction.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <quiz-grading-info :quiz="quiz" />
      </v-list>
    </div>

    <div class="center_and_fit_content">
      <p v-if="!quiz.active && quiz.quiz_type !== 'exam'">
        {{ $t("live.waitingForParticipants") }}, {{ $t("live.willBegin") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LanguageSwitch from "../../app/language_switch.vue"
import QuizGradingInfo from "./quiz_grading_info.vue"
import { useQuizSummary } from "@/composables/useQuizSummary"

export default {
  name: "QuizStudentInfo",
  components: { QuizGradingInfo, LanguageSwitch },
  props: {
    quiz: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: false, default: null },
    session: { type: Object, required: false, default: null },
    showLanguageSwitch: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showGrading: false,
      showInstructions: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    instructions() {
      return useQuizSummary({
        quiz: this.quiz,
        quizzesAttemptSummary: this.quizzesAttemptSummary,
        session: this.session,
      }).instructions.value
    },
  },
  methods: {
    ...mapActions(["updateCurrentUser"]),
    saveStudent() {
      this.updateCurrentUser(this.currentUser)
    },
  },
}
</script>

<style scoped>
  a {
    cursor: pointer;
  }
</style>

<style>
  .quiz-tips li {
    list-style-type: disc !important;
    margin-left: 1em;
  }

  .quiz-tips {
    max-width: 650px;
    margin: auto;
    padding: 2em;
  }

  .grading, .instructions {
    white-space: normal !important;
    overflow-wrap: break-word;
    padding-right: 15px;
  }
</style>
