<template>
  <div>
    <v-row
      justify="center"
      align="center"
      class="full_height_row"
    >
      <v-col class="col-md-8 col-sm-12">
        <quiz-onboarding
          v-if="quiz && quizzesAttemptSummary"
          :quiz="quiz"
          :school-user="schoolsUser"
          :quizzes-attempt-summary="quizzesAttemptSummary"
          @startQuiz="start()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuizOnboarding from "../../../components/quiz/onboarding/quiz_onboarding.vue"
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import redirectToScorePageMixin from "./redirect_to_score_page_mixin"
import EventsMixin from "../../../components/shared/events_mixin"

export default {
  name: "EvalmeeQuizOnboarding",
  components: { QuizOnboarding },
  mixins: [quizStudentMixin, redirectToScorePageMixin, EventsMixin],
  methods:{
    start() {
      const shouldAutoEnterFullscreen = process.env.DKT_NO_FULLSCREEN !== "true" && this.quiz.require_fullscreen

      if(shouldAutoEnterFullscreen) this.$emit("goFullscreen")
      this.registerEvent({ event: "enter_exam" })
      this.$router.push({ name: "exam", params: { quizId: this.quizId, quizzesAttemptSummaryId: this.quizzesAttemptSummaryId } })
    },
  },
}
</script>

