<template>
  <v-app id="evalmee-app">
    <loader2
      v-if="!currentUser || !quizLoaded"
      logo
    />


    <v-container v-else>
      <v-alert
        v-if="!quiz"
        type="info"
        text
      >
        {{ $t('quiz.lti.student.examNotVisible') }}
      </v-alert>

      <v-sheet
        v-if="quiz"
        color="transparent"
        width="600"
        class="center_and_fit_content mt-10"
      >
        <v-card
          outlined
          flat
        >
          <v-card-title>
            {{ quiz.name }}
            <v-spacer />
            <quiz-state-badge-student
              :quiz="quiz"
              :score="score"
            />
          </v-card-title>
          <quiz-student-info
            class="ma-4"
            :quiz="quiz"
            v-if="quiz"
          />
        </v-card>

        <v-sheet
          color="transparent"
          class="mt-3 center"
        >
          <!-- @todo: add link for correction-->
          <v-btn
            @click="openInBlankPage"
            color="primary"
            class="mt-4"
            depressed
          >
            {{ $t('quiz.lti.student.openExam') }}
          </v-btn>
        </v-sheet>
        <integration-footer />
      </v-sheet>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import QuizStudentInfo from "../../components/quiz/onboarding/quiz_student_info.vue"
import Loader2 from "../../components/shared/loader2.vue"
import QuizStateBadgeStudent from "../../components/quiz/quiz_state_badge_student.vue"
import IntegrationFooter from "../../components/shared/integration_footer.vue"
import axios from "axios"
import api from "@/api"

export default {
  name: "LtiQuizStudent",
  components: { IntegrationFooter, QuizStateBadgeStudent, Loader2, QuizStudentInfo },
  props: {
    quizId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    ltiStudentSyncing: false,
    quizLoaded: false,
  }),
  computed: {
    ...mapGetters(["quizById", "currentUser", "scoreByQuizAndUserId"]),
    quiz() {return this.quizById(this.quizId)},
    score() {
      return this.scoreByQuizAndUserId(this.quiz.id, this.currentUser.id)
    },
    examPath() {
      const route = this.$router.resolve({
        name: "quiz",
        params: {
          quizId: this.quizId,
        },
      })

      return `${api.rootUrl()}/evalmee${route.href}`
    },
  },

  methods: {
    ...mapActions([
      "fetchQuiz",
      "fetchCurrentUser",
      "updateLTISubscriptions",
      "fetchScoresByQuiz",
    ]),
    openInBlankPage() {
      axios.post(this.currentUser.links.auto_login_url).then((response) => {
        const autoLoginUrl = response.data.url
        const url = `${autoLoginUrl}&redirect=${this.examPath}`

        window.open(url, "_blank")
      })
    },
  },

  mounted() {
    this.fetchCurrentUser()
    this.fetchQuiz(this.quizId).finally(() => {
      this.fetchScoresByQuiz(this.quiz).finally(() => {
        this.quizLoaded = true
      })
    })
  },
}
</script>

<style scoped>

</style>
