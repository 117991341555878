import { onMounted, onUnmounted } from "vue"

export function useCaptureConsole(callback) {
  const originalConsoleLog = console.log
  const originalConsoleInfo = console.info

  onMounted(() => {
    if (console.log === originalConsoleLog) {
      console.log = function(...args) {
        originalConsoleLog(...args)
        callback("log", args)
      }
    }

    if (console.info === originalConsoleInfo) {
      console.info = function (...args) {
        originalConsoleInfo(...args)
        callback("info", args)
      }
    }
  })

  onUnmounted(() => {
    if(originalConsoleLog)  console.log = originalConsoleLog
    if(originalConsoleInfo) console.info = originalConsoleInfo
  })
}
