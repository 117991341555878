<template>
  <v-alert
    type="info"
    text
  >
    <v-row
      align="center"
      class="mb-0"
    >
      <v-col class="grow">
        {{ content }}
      </v-col>

      <v-col class="shrink">
        <eva-button
          primary
          @click="askToLeave"
          :label="buttonLabel"
        />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "FinishExamAlert",
  props: {
    quiz: {
      type: Object,
      required: true,
    },
  },
  computed: {
    content() {
      return this.canReview ?
        this.$t("live.modify.text") :
        this.$t("live.quizEnd.text")
    },
    buttonLabel() {
      return this.canReview ?
        this.$t("live.modify.exit") :
        this.$t("live.quizEnd.exit")
    },
    canReview() {
      return this.quiz?.allow_questions_navigation
    },
  },
  methods: {
    askToLeave() {
      this.$emit("askToLeave")
    },
  },
}
</script>

<style scoped>

</style>
