<template>
  <div>

    <disabled-webcam-help
      @retry="restartCamera"
      v-if="hasError"
    />

    <vue-web-cam
      ref="webcam"
      :device-id="deviceId"
      width="100%"
      height="300"
      @cameras="onCameras"
      @error="() => hasError = true"
      @video-live="hasError = false"
    />

    <v-select
      v-if="cameraSelectionMode"
      v-model="camera"
      :items="devices"
      item-text="label"
      item-value="deviceId"
      solo
      dense
      hide-details
      @change="saveCamera"
    ></v-select>

    <camera-shots-preview
      v-if="referencePhotoMode"
      class="pa-2"
      :required-image-count="3"
      :images="refImgs"
      :labels="positions"
      :capture-method="() => capture(refImgs)"
      :save-method="(img) => s3upload(img, refImgs,'anchors')"
      :delete-method="(img) => deleteImage(refImgs, img)"
    />

    <camera-shots-preview
      v-if="idCardMode"
      class="pa-2"
      :images="idCardImgs"
      :required-image-count="1"
      :labels="[this.$t('live.examOnboarding.identity.identityPhoto')]"
      :capture-method="() => capture(idCardImgs)"
      :save-method="(img) => s3upload(img, idCardImgs, 'id_card')"
      :delete-method="(img) => deleteImage(idCardImgs, img)"

    />

  </div>
</template>

<script>
import {WebCam} from "vue-web-cam"
import {mapActions, mapGetters} from "vuex"
import proctoringUploader from "../../../helpers/proctoring_uploader"
import CameraShotsPreview from "./camera_shots_preview.vue"
import {v1 as uuidv1} from "uuid"
import DisabledWebcamHelp from "./disabled-webcam-help.vue"


export default {
  name: "quizCameraTest",
  components: {
    DisabledWebcamHelp,
    CameraShotsPreview,
    "vue-web-cam": WebCam,
  },
  props: {
    quiz: {},
    mode: { type: String, default: "referencePhoto" },

  },
  data() {
    return {
      camera: null,
      deviceId: null,
      devices: [],
      refImgs: [],
      idCardImgs: [],
      hasError: false,
      positions: [
        this.$t("live.examOnboarding.webcamTest.photoFront"),
		      this.$t("live.examOnboarding.webcamTest.photoRight"),
		      this.$t("live.examOnboarding.webcamTest.photoLeft"),
      ],
      timer: null,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    folderName() {
      return proctoringUploader.folderName({
        quizId: this.quiz.id,
        userId: this.currentUser.id,
      })
    },
    cameraSelectionMode() { return this.mode === "cameraSelection" },
    referencePhotoMode() { return this.mode === "referencePhoto" },
    idCardMode() { return this.mode === "idCard" },
    asAnActiveCamera() {
      return this.devices.map(d => d.deviceId).includes(this.deviceId)
    },
  },
  watch: {
    camera: function(id) {
      this.deviceId = id
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices
      if (first) {
        // console.log({devies : this.devices, deivce: this.currentUser.camera_id})

        const savedDeviceIdExist = this.devices
          .map(x => x.deviceId)
          .includes(this.currentUser.camera_id)
        if(savedDeviceIdExist) {
          this.camera = this.currentUser.camera_id
          this.deviceId = this.currentUser.camera_id
        } else {
          this.deviceId = first.deviceId
          this.camera = first.deviceId
        }
        this.saveCamera()
      }
    },
    asAnActiveCamera(val) {
      this.$emit("asAnActiveCamera", val)
    },
    hasError(val) {
      if(!this.asAnActiveCamera) return
      this.$emit("asAnActiveCamera", !val)
    },
  },
  methods: {
    ...mapActions(["updateCurrentUser"]),
    restartCamera() {
      this.$refs.webcam.testMediaAccess()
      this.$refs.webcam.loadCamera()
      this.$refs.webcam.start()
    },
    onCameras(cameras) {
      this.devices = cameras
      console.log("On Cameras Event", cameras)
    },
    saveCamera() {
      this.currentUser.camera_id = this.camera
      this.updateCurrentUser(this.currentUser)
    },
    capture(imgs) {
      imgs.push({
        path: this.$refs.webcam.capture(),
        saved: false,
        id: uuidv1(),
      })
    },

    updateImageState(imgs, img) {
      imgs.find( i => i.id === img.id).saved = true
      this.emitStateChanges()
    },

    deleteImage(imgs, img) {
      let index = imgs.findIndex( i => i.id === img.id)
      imgs.splice(index, 1)
      this.emitStateChanges()
    },

    emitStateChanges() {
      this.$emit(
        "idCardVerificationDone",
        this.idCardImgs.filter(i => i.saved).length === 1
      )
      this.$emit(
        "videoCalibrationDone",
        this.refImgs.filter(i => i.saved).length === 3
      )
    },

    s3upload(img, imgs, subFolder = "") {
      const uploader = proctoringUploader.setup()
      return proctoringUploader.upload({
        uploader,
        imgBase64: img.path,
        path: this.folderName + "/" + subFolder,
        metadata: {},
        callback: () => {this.updateImageState(imgs, img)},
      })
    },
    startCameraDetectionLoop() {
      this.timer = setInterval(() => {
        if(this.isCameraStreaming()) return
        this.$refs.webcam.testMediaAccess()
      },1000)
    },
    stopCameraDetectionLoop() {
      clearInterval(this.timer)
    },
    isCameraStreaming(){
      return this.$refs?.webcam?.$refs?.video?.srcObject?.active === true
    },
  },
  mounted() {
    this.startCameraDetectionLoop()
  },
  beforeDestroy() {
    this.stopCameraDetectionLoop()
  },
}

</script>

<style scoped>
  .img-responsive{
    width: 100%;
  }
</style>
