<template>
  <v-row >
    <v-col
      cols="4"
      v-for="(img, index) in images"
      class="pa-2"
      :key="index"
    >
      <v-card class="pa-0" style="display: grid">
        <img :src="img.path" class="img-responsive" />
        <v-card-actions v-if="!img.saved">
          <v-btn
            fab
            dark
            color="red"
            @click="deleteMethod"
            v-tooltip="$t('live.examOnboarding.webcamTest.photoRetry')"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="green"
            @click="saveMethod(img)"
          >
            <v-icon>check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      v-if="showCaptureCard"
      cols="4"
      class="pa-2"
    >
      <v-card>
        <v-card-text>
          {{ currentPositionLabel }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            fab
            dark
            color="red"
            @click="captureMethod"
          >
            <v-icon>photo_camera</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "cameraShotsPreview",
  props: {
    images: { type: Array },
    labels: { type: Array },
    captureMethod: { type: Function },
    saveMethod: { type: Function },
    deleteMethod: { type: Function },
    requiredImageCount: { type: Number, default: 3 },
  },
  computed: {
    currentPositionLabel() {
      return this.labels[this.images.length]
    },
    showCaptureCard() {
      if(this.images.length === 0) return true
      if(this.images.length >= this.requiredImageCount) return false
      return this.images.every(i => i.saved)
    },
  },
}
</script>

<style scoped>
  .img-responsive{
    width: 100%;
  }
</style>
