<template>
  <div class="saving-screen center-align">
    <loader2 />

    <h3> {{ $t("quiz.scores.calculating") }}</h3>
    <p> {{ $t("quiz.scores.warning") }}</p>
    <br>
    <div
      v-if="showReSyncButton"
      class="center-align"
    >
      <p
        v-html="$t(&quot;quiz.scores.resync_description&quot;)"
      />
      <br>

      <v-btn
        @click="reSyncAnswers"
        color="primary"
      >
        {{ $t("quiz.scores.resync_button") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Loader2 from "../shared/loader2.vue"

export default {
  name: "SavingScreen",
  components: { Loader2 },
  props: {
    quiz: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  data: function () {
    return {
      showReSyncButton: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted: function(){
    const that = this
    setTimeout(function() { that.showReSyncButton = true }, 5000)
  },
  methods: {
    ...mapActions([
      "retrySyncAnswers",
    ]),
    reSyncAnswers(){
      this.retrySyncAnswers({ quiz: this.quiz, quizzesAttemptSummary: this.quizzesAttemptSummary })
    },
  },
}
</script>
