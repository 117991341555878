<template>
  <v-text-field
    :label="placeholder"
    :placeholder="placeholder"
    background-color="accentLight"
    name="user[email]"
    v-bind:value="email"
    v-on:input="$emit('update:email', $event)"
    type="email"
    :rules="[rules.required, rules.email]"
    flat
    autofocus
    filled
    solo
    validate-on-blur
  ></v-text-field>
</template>

<script>
export default {
  name: "emailInput",
  props: ["email", "placeholder"],
  data() {
    return {
      rules: {
        required: value => !!value || this.$t("user.emailInput.rules.required"),
        email: v => /.+@.+\..+/.test(v) || this.$t("user.emailInput.rules.email"),
      },
    }
  },
}
</script>

<style scoped>

</style>
