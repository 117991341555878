<template>
  <div id="evalmee-exam-student">
    <quiz-student-navigation
      v-if="!instancesLoading"
      :current-exercise.sync="currentExercise"
      :quiz="quiz"
      @askToLeave="() => askToLeaveDialog = true"
      :mini-drawer.sync="miniDrawerTemp"
      @update:permanent-drawer="(val) => $emit('update:permanent-drawer', val)"
      @update:drawer-is-visible="(val) => $emit('update:drawer-is-visible', val)"
    />

    <quiz-student-documents-drawer
      v-if="currentExercise && quizInstance && quiz"
      :current-exercise.sync="currentExercise"
      :instance="quizInstance"
      :quiz="quiz"
    />

    <finish-exam-alert
      v-if="isQuizFullyAnswered"
      :quiz="quiz"
      @askToLeave="() => askToLeaveDialog = true"
    />

    <v-expand-transition>
      <exercise-student
        v-if="currentExercise"
        :quiz="quiz"
        :quizzes-attempt-summary="quizzesAttemptSummary"
        :exercise="currentExercise"
        @questionAnswered="handleQuestionAnswered"
        :show-section-info="sections.length > 1"
        :correction-mode="showCorrection"
        :show-score="false"
      />
    </v-expand-transition>

    <v-row class="pa-0 pb-12 mb-12">
      <eva-button
        text
        v-if="showPreviousButton"
        @click="goToPreviousExercise"
        :label="$t('live.previous')"
      />
      <v-spacer />
      <eva-button
        :primary="nextButtonPrimary"
        v-if="showNextButton"
        @click="nextAction"
        :text="!nextButtonPrimary"
        :label="$t('live.next')"
        @keydown.enter.prevent="() => {}"
      />
    </v-row>

    <portal to="leaveExam">
      <v-btn
        @click="askToLeaveDialog = true"
        small
        outlined
        color="primary"
        class="ml-2"
      >
        {{ $t("live.quizEnd.exit") }}
      </v-btn>
    </portal>

    <portal to="examinfo">
      <quiz-student-menu-info
        v-if="quiz && quizInstance && quizzesAttemptSummary"
        :instance="quizInstance"
        :quiz="quiz"
        :schools-user="schoolsUser"
        :quizzes-attempt-summary="quizzesAttemptSummary"
      />
    </portal>

    <portal to="countdown">
      <countdown
        v-if="quiz && quizInstance"
        class="ml-2"
        :quizzes-attempt-summary="quizzesAttemptSummary"
        :quiz="quiz"
        @timeOver="timeOver"
      />
    </portal>
    <finish-exam-dialog
      v-if="quiz && quizzesAttemptSummary"
      :quiz="quiz"
      :instance="quizInstance"
      :quizzes-attempt-summary="quizzesAttemptSummary"
      :time-over="isTimeOver"
      v-model="askToLeaveDialog"
      @confirm="leaveExam"
    />

    <cheat-modal
      v-if="quiz"
      :current-quiz="quiz"
    />
    <cheat-modal2
      v-if="quiz"
      :active="true"
      :current-quiz="quiz"
      :current-exercise="currentExercise"
      :quizzes-attempt-summary="quizzesAttemptSummary"
    />

    <exam-loading
      :quizzes-attempt-summary="quizzesAttemptSummary"
      v-if="instancesLoading"
      :show-retry="allowToRetrySubjectFetch"
      @retry="fetchSubject"
    />
    <!--      <evalmee-exam-student-bottom-app-bar/>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import ExerciseStudent from "../../../components/quiz/editor/preview/exercise_preview.vue"
import QuizStudentNavigation from "../../../components/quiz/student_navigation/quiz_student_navigation.vue"
import FinishExamAlert from "./finish_exam_alert.vue"
import FinishExamDialog from "./finish_exam_dialog.vue"
import Countdown from "../../../components/quiz/evalmee/countdown.vue"
import QuizStudentMenuInfo from "../../../components/quiz/evalmee/quiz_student_menu_info.vue"
import instanceExerciseMixin from "../../../components/quiz/evalmee/instance_exercise_mixin"
import CheatModal from "../../../components/shared/cheat_modal.vue"
import CheatModal2 from "../../../components/shared/cheat_modal2.vue"
import QuizStudentDocumentsDrawer from "../../../components/quiz/quiz_student_documents_drawer.vue"
import ExamLoading from "./exam_loading.vue"
import redirectToScorePageMixin from "./redirect_to_score_page_mixin"
import fullscreenMixin from "../fullscreen_mixin"
import trackingHelpers from "../../../helpers/tracking_helpers"
import EventsMixin from "../../../components/shared/events_mixin"
import { useDeviceInfo } from "@/composables/useDeviceInfo"
import { useTimeoutFn } from "@vueuse/core"

export default {
  name: "EvalmeeExamStudent",
  components: {
    ExamLoading,
    QuizStudentDocumentsDrawer,
    CheatModal2,
    CheatModal,
    Countdown,
    FinishExamDialog,
    QuizStudentMenuInfo,
    FinishExamAlert,
    QuizStudentNavigation,
    ExerciseStudent,
  },
  mixins: [
    quizStudentMixin,
    instanceExerciseMixin,
    redirectToScorePageMixin,
    fullscreenMixin,
    EventsMixin,
  ],
  props: {
    miniDrawer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { getDeviceInfo: useDeviceInfo().getInfo }
  },
  data: () => ({
    currentExercise: null,
    askToLeaveDialog: false,
    isTimeOver: false,
    showCorrection: false,
    allowToRetrySubjectFetch: false,
  }),

  computed: {
    ...mapGetters([
      "nextUnansweredExerciseByQuizId",
      "isQuizFullyAnsweredById",
      "currentUser",
      "instancesLoading",
    ]),
    miniDrawerTemp: {
      get() { return this.miniDrawer },
      set(val) { this.$emit("update:miniDrawer", val) },
    },
    nextUnansweredExercise() {
      return this.nextUnansweredExerciseByQuizId(this.quizId, this.currentUser?.id)
    },
    showNavigationButton() {
      return this.currentExercise
    },
    showNextButton() {
      if(this.quiz?.correction_btw_questions && !this.showCorrection) return true
      if( this.isLastExercise)  return false
      return this.showNavigationButton
    },
    nextButtonPrimary() {
      return this.currentExercise?.isAnswered
    },
    showPreviousButton() {
      if(this.currenExerciseIndex === 0)         return false
      if(!this.previousExerciseInFilter)         return false
      if(!this.quiz?.allow_questions_navigation) return false
      return this.showNavigationButton
    },
    canAutoJumpToNextExercise(){
      if(!this.nextUnansweredExercise)          return false
      if(!this.currentExercise?.isAnswered )    return false
      if( this.quiz?.correction_btw_questions ) return false

      return !this.currentExercise?.requireExerciseValidation
    },
    isQuizFullyAnswered() {
      return this.isQuizFullyAnsweredById(this.quizId, this.currentUser?.id)
    },
    currenExerciseIndex() {
      return this.filteredExercises.findIndex( e => e.id === this.currentExercise?.id)
    },
    isLastExercise() {
      return this.currenExerciseIndex === this.filteredExercises.length - 1
    },
    currentExerciseIndexInFiltered() {
      return this.filteredExercises.findIndex( e => e.id === this.currentExercise?.id)
    },
    previousExerciseInFilter() {
      return this.filteredExercises[this.currentExerciseIndexInFiltered - 1 ]
    },
  },

  methods:{
    ...mapActions([
      "fetchQuiz",
      "startQuizzesAttemptSummary",
      "fetchScoresByQuiz",
      "setProctoring",
      "setShowCamera",
      "retrySyncAnswers",
      "closeQuizzesAttemptSummary",
    ]),
    goToNextUnansweredExercise() {
      this.currentExercise = this.nextUnansweredExercise
    },
    goToNextExercise() {
      this.currentExercise = this.filteredExercises[this.currentExerciseIndexInFiltered + 1 ]
    },
    nextAction() {
      if(
        !this.currentExercise?.isAnswered &&
        !this.quiz.allow_questions_navigation &&
        !this.showCorrection
      ) {
        return this.openSkipDialog()
      }
      this.goToCorrectionOrNextExercise()

    },
    goToCorrectionOrNextExercise() {
      if(this.quiz?.correction_btw_questions && !this.showCorrection) {
        this.showCorrection = true
        return
      }

      return this.goToNextExercise()
    },
    openSkipDialog() {
      this.$vuetifyConfirmDialog.destroy()
      this.$vuetifyConfirmDialog.open(
        this.$t("live.skipAnswer.dialog.title"),
        this.currentExercise.isImplicit ? this.$t("live.skipAnswer.dialog.implicitExercise") : this.$t("live.skipAnswer.dialog.explicitExercise"),
        this.$t("cancel"),
        this.$t("live.skipAnswer.dialog.confirm"),
        {
          cancelColor: "black",
          confirmColor: "primary",
        })
        .then(state => {
          if(state) {
            this.addMissingAnswerAndNext()
          }
        })
    },
    addMissingAnswerAndNext() {
      this.currentExercise.skipQuestionsWithoutAnswer({ quizzesAttemptSummary: this.quizzesAttemptSummary })
      this.goToCorrectionOrNextExercise()
    },
    goToPreviousExercise() {
      if(this.previousExerciseInFilter) {
        this.currentExercise = this.previousExerciseInFilter
      }
    },
    goToLastExercise() {
      this.currentExercise = this.exercises.slice(-1)[0]
    },
    leaveExam() {
      this.stopProctoring()
      this.stopScreenRecording()
      this.askToLeaveDialog = false
      this.leaveFullscreen()
      this.registerEvent({ event: "leave_exam" })
      if(this.quiz.state !== "preview") {
        trackingHelpers.trackEvent(
          "finishExam",
          { name: this.quiz?.name, id: this.quiz?.id }
        )
      }
      if(!this.isTimeOver) {
        this.closeQuizzesAttemptSummary(this.quizzesAttemptSummary).finally(this.goToScorePage)
      } else {
        this.goToScorePage()
      }
    },
    timeOver() {
      this.isTimeOver = true
      this.askToLeaveDialog = true
    },
    handleProctoring() {
      if(!this.quiz?.video_proctoring) return
      if(!this.quizzesAttemptSummary?.user_data_usage_accepted_at) return
      this.setShowCamera(true)
      this.setProctoring(true)
    },
    stopProctoring() {
      this.setShowCamera(false)
      this.setProctoring(false)
    },
    handleScreenRecording() {
      if(this.quiz?.screen_recording) {
        this.$root.$emit("enable-screen-share")
        if(this.quiz?.overlaying_app_detection) this.$root.$emit("enable-overlay-detection")
      }
    },
    stopScreenRecording() {
      this.$root.$emit("disable-screen-share")
      if(this.quiz?.overlaying_app_detection) this.$root.$emit("disable-overlay-detection")
    },
    handleQuestionAnswered() {
      if(this.canAutoJumpToNextExercise) this.goToNextExercise()
    },
    async createPageLoadEvent() {
      this.registerEvent({
        event: "page_load",
        details: await this.getDeviceInfo(),
      })

    },
    setupExamData() {
      this.createPageLoadEvent()

      this.fetchSubject()
        .then(() => {
          if(!["answering", "to_answer"].includes(this.quizzesAttemptSummary.answerability) ) this.timeOver()
          if(!["active", "preview"].includes(this.quiz?.state) ) this.timeOver()

        })
      this.handleProctoring()
      this.handleScreenRecording()
    },
    fetchSubject() {
      this.allowToRetrySubjectFetch = false
      const loadingTimer = useTimeoutFn(() => {this.allowToRetrySubjectFetch = true}, 3000)
      return this.startQuizzesAttemptSummary(this.quizzesAttemptSummary)
        .then(() => loadingTimer.stop())
    },
    reSyncAnswers(){
      this.retrySyncAnswers({ quiz: this.quiz, quizzesAttemptSummary: this.quizzesAttemptSummary })
    },
    async decodingMediaCapabilities() {
      const decodingMediaCapabilitiesTest = {}

      if (!navigator.mediaCapabilities) {
        console.log("Media Capabilities API is not supported in this browser.")
        return
      }

      const mediaConfigs = [
        { label: "VP8", config: { type: "file", video: { contentType: "video/webm; codecs=\"vp8\"", width: 1920, height: 1080, bitrate: 100000, framerate: 30 } } },
        { label: "H.264", config: { type: "file", video: { contentType: "video/mp4; codecs=\"avc1.42E01E\"", width: 1920, height: 1080, bitrate: 100000, framerate: 30 } } },
        { label: "HEVC", config: { type: "file", video: { contentType: "video/mp4; codecs=\"hevc\"", width: 3840, height: 2160, bitrate: 35000000, framerate: 60 } } },
      ]

      for (const mediaConfig of mediaConfigs) {
        try {
          const result = await navigator.mediaCapabilities.decodingInfo(mediaConfig.config)
          decodingMediaCapabilitiesTest[mediaConfig.label] = {
            supported: result.supported,
            smooth: result.smooth,
            powerEfficient: result.powerEfficient,
          }
        } catch (error) {
          decodingMediaCapabilitiesTest[mediaConfig.label] = { error: error.message }
        }
      }
      return decodingMediaCapabilitiesTest
    },
  },

  watch: {
    quiz:{
      handler() {
        this.handleProctoring()
        this.handleScreenRecording()
        if (this.quiz?.state === "over")
          this.timeOver()
      },
      immediate: true,
    },

    quizInstance: {
      handler(oldInstance, newInstance) {
        // If the same instance is reloaded, we should not change the current exercise
        if(oldInstance?.id === newInstance?.id) return

        if(!this.nextUnansweredExercise) return this.goToLastExercise()
        if(this.quizInstance) this.goToNextUnansweredExercise()
      },
      immediate: true,
    },
    score: {
      handler() {
        this.handleProctoring()
        this.handleScreenRecording()
      },
      immediate: true,
    },
    currentExercise(_newVal, oldVal) {
      document.getElementById("app")?.scrollIntoView(true)
      this.showCorrection = false
      if(oldVal) this.reSyncAnswers()
    },
    // DUPLICATE-#12
    "quizzesAttemptSummary.answerability": {
      handler(answerability, prevAnswerability) {
        if(!answerability) return
        if(["answering", "to_answer"].includes(answerability)) return
        // If the quizzesAttempt is closed while the student is on this page,
        // the time over dialog will be shown.
        // If the student open the page when the quizzesAttempt is already closed,
        // he is redirected to the score page.
        if(prevAnswerability) return this.timeOver()
        this.goToScorePage()

      },
      immediate: true,
    },
    quizzesAttemptSummary: {
      handler(val, previousVal) {
        if(previousVal) return
        if(!val) return
        if(!this.quiz) return this.fetchQuiz(this.quizId).then(this.setupExamData)

        this.setupExamData()
      },
      immediate: true,
    },
  },
}
</script>
