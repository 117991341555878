<template>
  <div class="d-inline-block">
    <span
      class="grey--text"
    >
      {{ navTitle }}
    </span>
    {{ quizzesAttemptSummary.quiz_name }}
    <span
      v-if="quizzesAttemptSummary.session_name"
      class="grey--text"
    >
      | {{ quizzesAttemptSummary.session_name }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "QuizStudentNavTitle",
  props: {
    quiz: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["orderedTagsByParent"]),
    navTitle() {
      if (! this.quiz?.tag_ids) return ""
      if (this.quiz?.tag_ids?.length === 0) return ""

      return this.orderedTagsByParent(this.quiz.tag_ids)
        .map( tag => tag?.name)
        .join(" / ") + " / "
    },
  },
}
</script>
