const states = [
  {
    name: "answered",
    icon: "mdi-check-circle",
    color: "green",
  },
  {
    name: "unanswered",
    icon: "mdi-circle-outline",
    color: "grey",
  },
  {
    name: "mdi-circle-off-outline",
    icon: "cancel",
    color: "orange",
  },
  {
    name: "all",
    icon: "mdi-dots-circle",
    color: "grey",
  },
]

export { states }
