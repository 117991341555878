import { useFullscreen } from "@vueuse/core"
import { computed } from "vue"

export default {
  setup() {
    const { isFullscreen, enter, exit, toggle } = useFullscreen()

    return {
      fullscreen: computed(() => isFullscreen.value),
      toggle,
      enter,
      exit,
    }
  },
  methods: {
    goFullscreen() {
      if (this.fullscreen) { return }
      this.enter()
    },
    leaveFullscreen() {
      if (!this.fullscreen) { return }
      this.exit()
    },
  },
}
