import { useEventListener } from "@vueuse/core"
import { ref } from "vue"
import { addListener, isLaunch, launch, stop } from "devtools-detector"


export function useDetectDevTool() {

  const isOpen = ref(false)

  const setOpen = (val) => {
    isOpen.value = val
  }

  addListener(val => {
    setOpen(val)
  } )

  const detect = () => {
    if(!isLaunch()) launch()
    // wait for 1 second before stopping the detector as it will clear the console on a frequent interval
    setTimeout(() => {
      stop()
    }, 1000)
  }

  useEventListener("resize", detect, { passive: true })
  useEventListener("mouseleave", detect, { passive: true })

  return { isOpen }
}
