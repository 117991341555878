<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    color="primary"
    solo
    filled
    background-color="accentLight"
    autofocus
    flat
    auto-select-first
    item-text="name"
    :hide-no-data="search === null || isLoading || search.length < 3"
    :no-data-text="$t('schools.searchSchool.noDataText')"
    :label="$t('schools.searchSchool.label')"
    :placeholder="$t('schools.searchSchool.placeholder')"
    prepend-inner-icon="search"
    append-icon=""
    return-object
  ></v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "searchSchool",
  methods: {
    ...mapActions([
      "fetchSchools",
    ]),
  },
  data: () => ({
    items: [],
    isLoading: false,
    model: null,
    search: "",
  }),

  computed: {
    ...mapGetters(["schools"]),
  },

  watch: {
    search (name) {
      if (!name || name.length < 3) {
        this.count = 0
        this.items = []
        return
      }

      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      this.fetchSchools({ name })
        .then(() => {
          this.count = this.schools.count
          this.items = this.schools
        })
        .finally(() => (this.isLoading = false))
    },

    model(newSelected) {
      if (newSelected) {
        this.$emit("select-school", newSelected)
        this.$nextTick(() => {
          this.model = null
          this.search = ""
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
