<template>
  <div v-if="isDisplayed">
    <div
      class="d-flex"
    >
      {{ remainingTimeFormatted.value }}
    </div>

    <v-progress-linear
      :value="countdownProgress.value"
      class="progress"
      :color="color"
    />
  </div>
</template>

<script>
import { useQuizzesAttemptSummary } from "@/composables/useQuizzesAttemptSummary"

export default {
  name: "QuizzesAttemptCountdown",
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    useQuizzesAttemptSummary() {
      return useQuizzesAttemptSummary(this.quizzesAttemptSummary)
    },
    remainingTimeFormatted() {
      return this.useQuizzesAttemptSummary.remainingTimeFormatted
    },
    remainingTime() {
      return this.useQuizzesAttemptSummary.remainingTime
    },
    countdownProgress() {
      return this.useQuizzesAttemptSummary.countdownProgress
    },
    isDisplayed() {
      if(!this.quizzesAttemptSummary.student_started_at) return false
      return !!this.quizzesAttemptSummary.submission_deadline_at ||
        !!this.quizzesAttemptSummary.end_at_with_accommodation
    },
    color() {
      if(this.countdownProgress.value > 95) return "orange"

      return "primary"
    },
  },
  methods: {
    timeOver() {
      this.$emit("timeOver")
    },
  },
  watch: {
    "remainingTime.value": {
      handler(val) {
        if(!val) return
        if(!this.isDisplayed) return
        if (val < 0) this.timeOver()
      },
      immediate: true,
    },
  },

}
</script>

<style scoped>
  .progress {
    height: 4px;
    margin-top: 0;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1000;
    border-radius: 0;
  }
</style>
