<template>
  <navigation-item
    :title="title"
    :subtitle-left="`${exercise.questionsCount} ${$tc('live.questionDrawer.questions',exercise.questionsCount)}`"
    :subtitle-right="rightSubtitle"
    :selectable="selectable"
    :disabled="!selectable"
    :value="exercise.id"
    :compact="compact"
    :faded="!inFilter"
  >
    <template #icon>
      <template v-if="compact">
        <v-badge
          overlap
          :value="exercise.isAnswered"
          color="green"
          dot
          class="center"
        >
          <div>
            {{ title }}
          </div>
        </v-badge>
      </template>
      <question-state-icon
        v-else
        :state="exercise.isAnswered ? 'answered' : 'unanswered'"
        :progress-percent="exercise.progressPercent"
        small
      />
    </template>
  </navigation-item>
</template>


<script>

import NavigationItem from "./navigationItem.vue"
import QuestionStateIcon from "./questionStateIcon.vue"

export default {
  name: "ExerciseNavigationItem",
  components: { QuestionStateIcon, NavigationItem },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    withNavigation: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: "all",
      validator: (val) => ["all", "unanswered", "answered"].includes(val) ,
    },
  },
  computed: {
    selectable() {
      return this.withNavigation
    },
    title() {
      if(this.compact) return this.index + 1
      if(this.withNavigation) return this.exercise.displayName

      return `Page ${this.index + 1}`
    },
    rightSubtitle() {
      if(this.withNavigation) return `Page ${this.index + 1}`

      return ""
    },
    inFilter() {
      if(this.filter === "all") return true
      if(this.filter === "answered") return this.exercise.isAnswered
      if(this.filter === "unanswered") return !this.exercise.isAnswered
      return true
    },
  },
}

</script>


