<template>
  <component
    :is="isListItem ? 'v-list-item' : 'div'"
    dense
    class="very-dense"
    :class="{
      'd-flex': !isListItem && !compact,
      'faded': faded,
      'header-item-max-width': !isListItem && !compact,
    }"
    :value="value"
    :disabled="disabled"
  >
    <template v-if="compact">
      <v-list-item-content class="overflow-visible">
        <template>
          <slot name="icon" />
          <v-progress-linear
            v-if="progressPercent !== null && progressBar"
            :value="progressPercent"
            rounded
          />
        </template>
      </v-list-item-content>
    </template>

    <template v-else>
      <v-list-item-avatar
        min-width="0"
        min-height="0"
        height="auto"
        width="auto"
        class="mr-2"
        tile
      >
        <slot name="icon" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <katex-md
            :expr="removeHTML(title)"
            :linkify="false"
            only-text
            inline-text
            remove-all-html
            truncate
          />
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="progressBar"
          class="my-1"
        >
          <v-progress-linear
            v-if="progressPercent !== null"
            :value="progressPercent"
            rounded
          />
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <div class="d-flex">
            <div class="caption">
              {{ subtitleLeft }}
            </div>
            <v-spacer />
            <div class="caption">
              {{ subtitleRight }}
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </component>
</template>


<script>

import helpers from "../../../helpers"
import KatexMd from "../../katex-md.vue"

export default {
  name: "NavigationItem",
  components: { KatexMd },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitleLeft: {
      type: String,
      default: null,
    },
    subtitleRight: {
      type: String,
      default: null,
    },
    progressBar: {
      type: Boolean,
      default: false,
    },
    progressPercent: {
      type: Number,
      default: null,
      validate: (value) => value >= 0 && value <= 100,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isListItem: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    faded: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    removeHTML: helpers.removeHTML,
  },

}

</script>


<style scoped>

.faded::v-deep * {
  opacity: 0.8;
}
.overflow-visible{
  overflow: visible !important;
}

.header-item-max-width{
  max-width: calc(100% - 30px);
}
</style>
