<template>
  <v-col class="pa-0" md="7" sm="10" xs="12">
    <v-card class="pa-2 pb-4 pt-4">
      <v-form @submit.prevent="submitPasswordReset" ref="passwordResetForm">

        <v-alert class="ma-4 text-left" :value="!!notification.type" transition="fade-transition" :type="notification.type">{{ notification.message }}</v-alert>

        <v-card-text>
          <password-input
            v-bind:password.sync="user.password"
            v-bind:passwordConfirmation.sync="user.password_confirmation"
            with-confirmation/>
          <v-text-field
            v-show="false"
            name="user[reset_password_token]"
            v-model="user.reset_password_token"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="mx-2">
          <v-btn
            type="submit"
            block
            large
            color="primary"
            name="password-reset-submit"
          >
            {{ $t("user.passwordReset.change") }}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="mx-2">
          <a href="/evalmee#/user/login?passwordReset=true">{{ $t("user.passwordReset.link") }}</a>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import passwordInput from "../components/users/passwordInput.vue"
import { mapActions } from "vuex"
import api from "../api"


export default {
  name: "passwordReset",
  components: {
    passwordInput,
  },
  data() {
    return {
      notification: {
        type: null,
        message: null,
      },
      user: {
        email: "",
        password: "",
        password_confirmation: "",
        reset_password_token: this.$route.query.reset_password_token,
      },
    }
  },
  methods: {
    ...mapActions(["resetPassword", "requestPasswordReset"]),
    submitPasswordReset() {
      if (this.$refs.passwordResetForm.validate())
        this.resetPassword(this.user).then(this.redirectToHome).catch(this.displayError)
    },
    redirectToHome(response) {
      window.location.href = response.data.data.links.home
    },
    redirectToLogin() {
      window.location.href = api.v1.userNewLoginUrl
    },
    displayError(reason) {
      console.log(reason)
      this.notification.type = "error"
      this.notification.message = this.$t("user.passwordReset.error", { error: reason.response.data.errors.reset_password_token[0] })
    },
  },
}
</script>

<style scoped>

</style>
