<template>
  <v-list
    dense
    nav
    color="transparent"
  >
    <exercise-navigation-item
      v-for="(exercise, index) in filteredExercises"
      :index="index"
      :exercise="exercise"
      :key="exercise.id"
      :with-navigation="navigable"
      :compact="miniDrawer"
      :filter="filter"
      :ref="`exercise-${exercise.id}`"
    />
  </v-list>
</template>

<script>
import ExerciseNavigationItem from "./exerciseNavitationItem.vue"

export default {
  name: "QuizStudentExercisesNavigation",
  components: { ExerciseNavigationItem },
  props: {
    currentExerciseId: {
      type: Number,
      default: null,
    },
    exercises: {
      type: Array,
      required: true,
    },
    miniDrawer: {
      type: Boolean,
      default: false,
    },
    navigable: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: String,
      default: "all",
      validator: (value) => ["answered", "unanswered", "all"].includes(value),
    },
  },
  computed: {
    tempCurrentExerciseId: {
      get() { return this.currentExerciseId },
      set(id) {
        console.log("setCurrentExerciseId", id)
        if(!this.navigable) return
        this.emitChange(id)
      },
    },
    filteredExercises() {
      if(this.filter === "answered") {
        // all exercises with at least one answered questions
        return this.exercises.filter((exercise) => exercise.questions.some((q) => q.isAnswered && q.isAnswerable) || exercise.id === this.currentExerciseId)
      } else if(this.filter === "unanswered") {
        // all exercises with at least one unanswered questions
        return this.exercises.filter((exercise) => exercise.questions.some((q) => !q.isAnswered && q.isAnswerable) || exercise.id === this.currentExerciseId)
      } else {
        return this.exercises
      }
    },
  },
  methods: {
    emitChange(val) {
      this.$emit("update:current-exercise-id", val)
    },
    scrollToExercise(id){
      const el = this.$refs[`exercise-${id}`]
      if(!el) {return}
      el[0].$el.scrollIntoView({ behavior: "smooth", block: "center" })
    },
  },
  watch: {
    currentExerciseId: {
      handler(val) {
        this.scrollToExercise(val)
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.answered_exercise {
}
.answered_exercise:before {
  background: #4caf50 !important;
  opacity: 0.12;
}

.v-list-item--active.answered_exercise:before {
  background: rgba(0,0,0,.6) !important;
  opacity: 0.12;
}

.answered_exercise.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #4caf50 !important;
}
</style>
