<template>
  <v-app id="evalmee-app">
    <loader2
      v-if="!quizLoaded"
      logo
    />

    <v-container v-else>
      <logo-home-student
        :school="school"
        class="center mt-6"
      />
      <v-alert
        v-if="!quiz"
        type="info"
        text
      >
        {{ $t('quiz.lti.student.examNotVisible') }}
      </v-alert>

      <v-sheet
        v-if="quiz"
        color="transparent"
        class="mt-10 mx-auto"
        max-width="500"
      >
        <v-card
          outlined
          flat
        >
          <v-card-title>
            <div
              class="d-flex align-center"
              style="max-width: 100%; gap: 8px; width: 100%"
            >
              <div class="d-inline-block ellipsis">
                {{ quiz.name }}
                <span class="grey--text">
                  | {{ publicSession.name }}
                </span>
              </div>
              <v-spacer />
              <div>
                <evalmee-chip
                  :icon="quizSession.icon.value"
                  :color="quizSession.color.value"
                  small
                >
                  {{ quizSession.state.value.label }}
                </evalmee-chip>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <quiz-student-info
              class="ma-2"
              :quiz="quiz"
              :session="publicSession"
              v-if="quiz"
              :show-language-switch="false"
              dense
            />
            <eva-button
              v-if="userIsLoggedIn"
              primary
              block
              :label="$t('evalmee.publicJoinSession.join')"
              @click="enroll"
            />
          </v-card-text>
        </v-card>

        <div v-if="!emailSent && !userIsLoggedIn">
          <v-card
            flat
            outlined
            class="mt-3"
          >
            <v-card-title>
              Rejoindre l'examen
            </v-card-title>
            <v-card-text>
              <div v-if="ssoProviders.length > 0">
                <eva-button
                  v-for="provider in ssoProviders"
                  :key="provider"
                  color="primary"
                  block
                  class="mb-4"
                  :href="`${provider.login_href}?redirect_url=${redirectUrl}`"
                >
                  {{ $t("user.userLogin.log_in_with", {provider: provider.display_name}) }}
                </eva-button>

                <v-row
                  align="center"
                  class="my-4 text-uppercase"
                >
                  <v-divider class="mr-8" />{{ $t("user.userLogin.or")
                  }}<v-divider class="ml-8" />
                </v-row>
              </div>

              <eva-text-field
                v-model="email"
                :placeholder="$t('evalmee.publicJoinSession.emailField.label')"
                dense
                :rules="emailRules"
                validate-on-blur
                ref="email"
                hide-details="auto"
                class="mb-2"
              />
              <div
                v-if="publicSession.allow_self_enrollment === 'in_school'"
                class="mb-4"
              >
                <span v-html="$t('evalmee.publicJoinSession.emailField.domainTips', {allowedEmailDomains})" />
              </div>

              <v-expand-transition>
                <div v-if="showNameFields">
                  <eva-text-field
                    v-model="firstName"
                    placeholder="Prénom"
                    hide-details="auto"
                    dense
                    class="mb-2"
                    required
                  />
                  <eva-text-field
                    v-model="lastName"
                    placeholder="Nom"
                    hide-details="auto"
                    dense
                    required
                  />
                </div>
              </v-expand-transition>
            </v-card-text>

            <v-card-actions class="mx-2">
              <eva-button
                v-if="!showNameFields"
                @click="checkIfUserExistsForEmail"
                primary
                block
                :label="$t('evalmee.publicJoinSession.next')"
                :outlined="ssoProviders.length > 0"
              />
              <eva-button
                v-else
                @click="signup"
                primary
                block
                :label="$t('evalmee.publicJoinSession.join')"
              />
            </v-card-actions>
          </v-card>
        </div>

        <v-alert
          v-if="emailSent"
          color="primary"
          text
          dense
          class="mt-4"
        >
          {{ $t("user.userLogin.sendedEmail", {email: email}) }}
        </v-alert>
      </v-sheet>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import QuizStudentInfo from "../../components/quiz/onboarding/quiz_student_info.vue"
import Loader2 from "../../components/shared/loader2.vue"
import LogoHomeStudent from "@/components/app/logo_home_student.vue"
import EvaTextField from "@/components/shared/inputs/eva-text-field.vue"
import axios from "axios"
import api from "@/api"
import { useQuizSession } from "@/composables/useQuizSession"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"

export default {
  name: "QuizPublicLogin",
  components: { EvalmeeChip, EvaTextField, LogoHomeStudent, Loader2, QuizStudentInfo },
  props: {
    publicSessionSlug: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    quizLoaded: false,
    email: "",
    firstName: "",
    lastName: "",
    step: 0,
    userExists: null,
    emailSent: false,
  }),
  computed: {
    ...mapGetters(["quizPublicSessionByPublicToken", "schools", "quizById", "currentUser"]),
    quiz() {return this.quizById(this.publicSession?.quiz_id)},
    publicSession() {
      return this.quizPublicSessionByPublicToken(this.publicSessionSlug.split("-")[0])
    },
    quizSession() {
      return useQuizSession(this.publicSession)
    },

    school() {
      return this.schools.find((school) => school.id === this.quiz?.school_id)
    },
    showNameFields() {
      return this.userExists === false
    },
    allowedEmailDomains() {
      return this.school?.sso_allowed_domains?.map((domain) => `@${domain}`)?.join(", ")
    },
    emailRules() {
      // validate email formate
      // if (this.publicSession.allow_self_enrollment === "in_school") validate email domain is in school allowed domains

      return [
        (v) => !!v || this.$t("user.userLogin.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("user.userLogin.emailInvalid"),
        (v) => {
          if (this.publicSession.allow_self_enrollment === "in_school") {
            return this.school?.sso_allowed_domains?.some((domain) => v.endsWith(`@${domain}`)) || this.$t("user.userLogin.emailDomainNotAllowed")
          }
          return true
        },
      ]
    },

    ssoProviders() {
      return this.school.sso_providers || []
    },

    userIsLoggedIn() {
      return !!this.currentUser?.id
    },
    redirectUrl() {
      return encodeURIComponent(api.legacy.evalmeeStudentUrl() + "#" + this.$route.path + "?autojoin=true")
    },
  },

  methods: {
    ...mapActions([
      "fetchPublicSession",
    ]),
    checkIfUserExistsForEmail() {
      // return if email is not valid
      if (!this.$refs.email.validate()) return

      axios.post(api.v1.userExistsForEmailUrl(), { email: this.email }).then((response) => {
        this.userExists = response.status === 200
        this.signup()
      }).catch(() => {
        this.userExists = false
      })
    },
    signup() {
      axios.post("/api/v1/users/signup", {
        user: {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
        },
        redirect_url: this.redirectUrl,
      }).then(() => {
        this.emailSent = true
      })
    },
    enroll() {
      axios
        .post(this.publicSession.links.enrollment)
        .then(() => {
          console.log("enrolled")
          console.log({ quizId: this.publicSession.quiz_id })
          this.$router.push({ name: "quiz", params: { quizId: this.publicSession.quiz_id } })
        })
    },
  },

  mounted() {
    this.fetchPublicSession(this.publicSessionSlug).finally(() => {
      this.quizLoaded = true
    })

  },
  watch: {
    publicSession: {
      handler(val) {
        if( !val) return

        if(this.$route.query.autojoin === "true") {
          this.enroll()
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.ellipsis  {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
