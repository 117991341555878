import userLogin from "../../views/userLogin.vue"
import passwordReset from "../../views/passwordReset.vue"
import userProfileContainer from "../../views/user_profile/userProfileContainer.vue"

const evalmeeLogin = [
  {
    path: "/user",
    component: userProfileContainer,
    children: [
      {
        path: "login",
        name: "userLogin",
        component: userLogin,
      },
      {
        path: "password/edit",
        name: "passwordReset",
        component: passwordReset,
        beforeEnter: (to, from, next) => {
          if (to.query.reset_password_token)
            next()
          else
            next({ name: "userLogin" })
        },
      },
    ],
  },
]

export {
  evalmeeLogin,
}

