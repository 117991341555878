export default {
  computed: {

    isAnswered() {
      return ["finished", "timeout_closed", "timeout_timer", "paper"].includes(this.score?.state)
    },
    isQuizAccessible() {
      return ["active", "published"].includes(this.quiz.state)
    },
    isClosed() {
      return !this.isQuizAccessible && !this.isAnswered
    },
    isStarted() {
      return this.score?.state === "started"
    },
  },
}
