<template>
  <v-col
    v-if="!isFetching"
    class="pa-0"
    md="7"
    sm="10"
    xs="12"
  >
    <v-card
      class="pa-2 pb-4"
      outlined
      flat
    >
      <v-card-title class="text-h6 font-weight-regular">
        <v-btn
          icon
          class="mr-2"
          @click="step = currentStep.returnToStep"
          v-if="step > 1"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>{{ currentStep.title }}</span>
      </v-card-title>

      <v-window
        v-model="step"
        class="text-left"
      >
        <v-alert
          class="ma-4 text-left"
          :value="!!notification.type"
          transition="fade-transition"
          :type="notification.type"
        >
          {{ notification.message }}
        </v-alert>

        <v-window-item
          :value="1"
          eager
        >
          <v-card-text>
            <search-school @select-school="selectSchool" />
            <a @click="toggleHelp">{{ $t("user.userLogin.cantFindSchool") }}</a>
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="2"
          eager
        >
          <v-card-text>
            <div v-if="ssoProviders.length > 0">
              <eva-button
                v-for="provider in ssoProviders"
                :key="provider"
                color="primary"
                block
                large
                class="mb-4"
                :href="provider.login_href"
              >
                {{ $t("user.userLogin.log_in_with", {provider: provider.display_name}) }}
              </eva-button>

              <v-row
                align="center"
                class="my-4 text-uppercase"
              >
                <v-divider class="mr-8" />{{ $t("user.userLogin.or")
                }}<v-divider class="ml-8" />
              </v-row>
            </div>

            <v-form
              id="passwordless-login-form"
              @submit.prevent="submitPasswordLessLogin"
              ref="passwordlessLoginForm"
            >
              <email-input
                class="mb-n6"
                :email.sync="user.email"
                :placeholder="emailPlaceholder"
              />
            </v-form>
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="3"
          eager
        >
          <v-card-text>
            <div v-if="ssoProviders.length > 0">
              <eva-button
                v-for="provider in ssoProviders"
                :key="provider"
                color="primary"
                block
                large
                class="mb-4"
                :href="provider.login_href"
              >
                {{ $t("user.userLogin.log_in_with", {provider: provider.display_name}) }}
              </eva-button>

              <v-row
                align="center"
                class="my-4 text-uppercase"
              >
                <v-divider class="mr-8" />{{ $t("user.userLogin.or")
                }}<v-divider class="ml-8" />
              </v-row>
            </div>

            <v-form
              id="user-login-form"
              @submit.prevent="submitUserLogin"
              ref="userLoginForm"
            >
              <email-input
                :email.sync="user.email"
                :placeholder="emailPlaceholder"
              />

              <password-input :password.sync="user.password" />
            </v-form>

            <a @click="step = 4">{{ $t("user.userLogin.forgotPassword") }}</a>
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="4"
          eager
        >
          <v-card-text>
            <v-form
              id="password-reset-form"
              ref="passwordResetForm"
              @submit.prevent="submitPasswordReset"
            >
              <email-input
                :email.sync="user.email"
                :placeholder="emailPlaceholder"
              />
            </v-form>
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="5"
          eager
        >
          <v-card-text>
            <v-alert
              color="info"
              class="mt-n4 text-left"
              text
              dense
            >
              {{ $t("user.userLogin.sendedEmail", {email: user.email}) }}
            </v-alert>
            {{ $t("user.userLogin.sendedEmailInfo") }}
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="6"
          eager
        >
          <v-card-text>
            <v-alert
              color="info"
              text
              dense
            >
              <span v-html="$t('user.userLogin.expiredToken')" />
            </v-alert>
          </v-card-text>
        </v-window-item>

        <v-window-item
          :value="7"
          eager
        >
          <v-card-text>
            <v-alert
              color="info"
              text
              dense
            >
              <span v-html="$t('user.userLogin.emailDomainNotAllowed.content', {domain: $route.query.email_domain_not_permitted})" />
            </v-alert>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-card-actions class="mx-2">
        <eva-button
          type="submit"
          block
          large
          :outlined="step == 2 && ssoProviders.length > 0"
          primary
          :form="currentStep.formId"
          formmethod="post"
          v-if="step > 1 && step < 5"
          name="user-submit"
          :value="currentStep.btn"
        >
          {{ currentStep.btn }}
        </eva-button>
      </v-card-actions>

      <v-alert
        color="info"
        class="mt-4 mx-4 text-left"
        text
        dense
        v-if="step == 2"
      >
        {{ $t("user.userLogin.passwordLessInfo") }}
        <a
          class="font-weight-bold"
          @click="step = 3"
        >
          {{ $t("user.userLogin.passwordLessInfoAction") }}
        </a>
      </v-alert>
    </v-card>
  </v-col>
</template>

<script>
import searchSchool from "../components/schools/searchSchool.vue"
import emailInput from "../components/users/emailInput.vue"
import { mapActions, mapGetters } from "vuex"
import passwordInput from "../components/users/passwordInput.vue"
import chatSupportHelpers from "../helpers/chat_support_helpers.js"

export default {
  name: "UserLogin",
  components: {
    searchSchool,
    emailInput,
    passwordInput,
  },
  data() {
    return {
      notification: {
        type: null,
        message: null,
      },
      showPassword: false,
      step: 1,
      selectedSchool: {},
      isFetching: true,
      user: {
        email: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || this.$t("user.userLogin.required"),
        password: () =>
          this.user.password.length >= 8 || this.$t("user.edit.passwordMin"),
      },
    }
  },
  methods: {
    ...mapActions([
      "loginUser",
      "newAppNotification",
      "requestPasswordReset",
      "fetchSchool",
      "fetchSchools",
      "passwordLessLogin",
    ]),
    selectSchool(school) {
      this.selectedSchool = school
      this.step++
    },
    submitUserLogin() {
      if (this.$refs.userLoginForm.validate()) {
        this.loginUser(this.user).then(this.redirect).catch(this.displayError)
      }
    },
    submitPasswordLessLogin() {
      if (this.$refs.passwordlessLoginForm.validate()) {
        this.passwordLessLogin({ user: this.user }).then(this.step = 5).catch(this.displayError)
      }
    },
    submitPasswordReset() {
      if (this.$refs.passwordResetForm.validate()) {
        this.requestPasswordReset(this.user)
          .then(this.displayResponse)
          .catch(this.displayError)
      }
    },
    redirect(response) {
      window.location.href =
        this.$route.query.redirect_to || response.data.data.links.home
    },
    displayResponse(response) {
      this.notification.type = "success"
      this.notification.message = response.data.status.message
    },
    displayError(reason) {
      this.notification.type = "error"
      this.notification.message = reason.response.data.status.message
    },
    toggleHelp() {
      chatSupportHelpers.showCrispChat()
    },

    getSchool() {
      const schoolName = this.$route.query.schoolName
      if (schoolName) {
        this.fetchSchools(schoolName)
          .then(() => {
            if (this.step === 1) this.step = 2
            if (this.schools) {
              this.selectedSchool = this.schools.find(
                (school) => school.name === schoolName
              )
            }
          })
          .finally(() => {
            this.isFetching = false
          })
      }
      else if (localStorage.schoolId) {
        this.fetchSchool(localStorage.schoolId)
          .then(() => {
            if (this.step === 1) this.step = 2
            if (this.schools) {
              this.selectedSchool = this.schools.find(
                (school) => school.id === parseInt(localStorage.schoolId)
              )
            }
          })
          .finally(() => {
            this.isFetching = false
          })
      }
      this.isFetching = false
    },

    getEmail() {
      const email = this.$route.query.email
      if (email) this.user.email = email
      else if (localStorage.email) this.user.email = localStorage.email
    },
  },
  computed: {
    ...mapGetters(["school", "schools", "currentSchool"]),
    ssoProviders() {
      return this.selectedSchool.sso_providers || []
    },
    currentStep() {
      switch (this.step) {
      case 1:
        return {
          title: null,
          btn: null,
          formId: null,
          returnToStep: 1,
        }
      case 2:
        return {
          title: this.selectedSchool.name,
          btn: this.$t("user.userLogin.passwordLessLogin"),
          formId: "passwordless-login-form",
          returnToStep: 1,
        }
      case 3:
        return {
          title: this.selectedSchool.name,
          btn: this.$t("user.userLogin.login"),
          formId: "user-login-form",
          returnToStep: 2,
        }
      case 4:
        return {
          title: this.$t("user.userLogin.passwordReset"),
          btn: this.$t("user.userLogin.passwordReset"),
          formId: "password-reset-form",
          returnToStep: 3,
        }
      case 5:
        return {
          title: this.selectedSchool.name,
          btn: null,
          formId: null,
          returnToStep: 2,
        }
      case 6:
        return {
          title: this.$t("user.userLogin.expiredTokenTitle"),
          btn: null,
          formId: null,
          returnToStep: 2,
        }
      case 7:
        return {
          title: this.$t("user.userLogin.emailDomainNotAllowed.title"),
          btn: null,
          formId: null,
          returnToStep: 2,
        }
      default:
        return {
          title: this.$t("user.userLogin.passwordReset"),
          btn: this.$t("user.userLogin.passwordReset"),
          formId: "password-reset-form",
          returnToStep: 3,
        }
      }
    },
    emailPlaceholder() {
      if (this.selectedSchool.login_form_message)
        return "Email " + this.selectedSchool.login_form_message
      else return "Email"
    },
  },
  mounted() {
    this.getSchool()
    this.getEmail()

    if (this.$route.query.expiredToken) this.step = 6
    if (this.$route.query.passwordReset) this.step = 4
    if (this.$route.query.email_domain_not_permitted) this.step = 7
  },
  watch: {
    selectedSchool(newSchool) {
      if (newSchool.id) localStorage.schoolId = newSchool.id
    },
    user: {
      handler(newUser) {
        if (newUser.email) localStorage.email = newUser.email
      },
      deep: true,
    },
    step() {
      this.notification.message = null
      this.notification.type = null
    },
  },
}
</script>

<style scoped></style>
