<template>
  <div :class="classes">
    <evalmee-chip
      v-if="currentStatus.correctionTag"
      color="green"
      class="mr-2"
      label
      small
    >
      {{ $t('activities.correction') }}
    </evalmee-chip>
    <evalmee-chip
      small
      :color="currentStatus.color"
      label
      :icon="currentStatus.icon"
    >
      {{ currentStatus.label }}
    </evalmee-chip>
  </div>
</template>

<script>
import EvalmeeChip from "../shared/evalmee_chip.vue"

export default {
  name: "QuizzesAttemptSummaryStateStudent",
  components: { EvalmeeChip },
  props: {
    quizzesAttemptSummary: { type: Object, required: true },
    classes: { type: String, default: "ma-2" },
  },
  computed: {
    currentStatus() {
      return {
        not_answerable_yet: {
          label: this.$t("activities.notAnswerableYet"),
          color: "teal",
          correctionTag: false,
          icon: "mdi-dots-circle",
        },
        to_answer: {
          label: this.$t("activities.toAnswer"),
          color: "cyan",
          correctionTag: false,
          icon: "mdi-checkbox-blank-circle-outline",
        },
        answered: {
          label: this.$t("activities.answered"),
          color: "green",
          correctionTag: this.quizzesAttemptSummary.correction_is_visible,
          icon: "mdi-check-circle-outline",
        },
        answering_in_another_session: {
          label: this.$t("activities.answeringInAnotherSession"),
          color: "blue-grey",
          correctionTag: false,
          icon: "mdi-minus-circle-outline",
        },
        answered_in_another_session: {
          label: this.$t("activities.answeredInAnotherSession"),
          color: "blue-grey",
          correctionTag: false,
          icon: "mdi-minus-circle-outline",
        },
        answering: {
          label: this.$t("activities.answering"),
          color: "blue",
          correctionTag: false,
          icon: "mdi-circle-slice-3",
        },
        ended_and_not_answered: {
          label: this.$t("activities.endedAndNotAnswered"),
          color: "red",
          correctionTag: false,
          icon: "mdi-close-circle-outline",
        },
      }[this.quizzesAttemptSummary.answerability]
    },
  },

}
</script>
