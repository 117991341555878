export default {
  methods: {
    goToScorePage() {
      this.$router.push({ name: "score", params: { quizId: this.quizId, quizzesAttemptSummaryId: this.quizzesAttemptSummaryId } })
    },
  },

  watch: {
    // DUPLICATE-#12
    "quizzesAttemptSummary.answerability": {
      handler(answerability) {
        if(!answerability) return
        if(["answering", "to_answer", "not_answerable_yet"].includes(answerability)) return

        this.goToScorePage()

      },
      immediate: true,
    },
  },
}
