<template>
  <span>
    <slot :clock="clock">
      {{ clock }}
    </slot>
  </span>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "realTimeClock",
  data: () => ({
    interval: null,
    clock: "",
  }),
  methods: {
    updateClock() {
      this.clock = this.$moment(this.serverTime()).locale("fr").format("H[h] mm[min]")
    },
    serverTime() {
      if(this.timeOffset == null) throw "Load an instance before showing the current time."
      return Date.now() + parseInt(this.timeOffset)
    },
  },
  computed: {
    ...mapGetters(["timeOffset"]),
  },
  mounted() {
    this.interval = setInterval( this.updateClock, 1000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped>

</style>
