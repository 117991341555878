<template>
  <div>
    <div
      v-if="quizzesLoading && quizzesAttemptSummaries.length === 0"
      class="mt-5 center"
    >
      <v-skeleton-loader
        type="text"
        max-width="100"
        class="mb-3"
      />
      <v-card
        outlined
        flat
      >
        <v-skeleton-loader
          v-for="index in 3"
          :key="index"
          type="list-item-two-line"
        />
      </v-card>
    </div>

    <v-scroll-y-reverse-transition>
      <div v-if="!quizzesLoading">
        <div
          v-if="quizzesAttemptSummaries.length === 0"
          class="center"
        >
          <img
            src="@/assets/images/undraw_exams_g4ow.svg?url"
            height="300px"
            class="mb-10 mt-15"
          >
          <div class="mb-15">
            <b>
              {{ $t("evalmee.home.student.noExam.title") }}
            </b>
            <div class="text-caption">
              {{ $t("evalmee.home.student.noExam.subtitle") }}
            </div>
          </div>
        </div>
      </div>
    </v-scroll-y-reverse-transition>

    <v-alert
      dense
      text
      :icon="false"
      type="info"
      v-if="hasHiddenLtiQuizzes"
    >
      <span v-html="$t('evalmee.home.student.managedExamInfo.content', {ltiNames})" />
      <a @click="openSupportChat">
        {{ $t("evalmee.home.student.managedExamInfo.openChat") }}
      </a>
    </v-alert>

    <template v-if="quizzesAttemptSummaries.length > 0">
      <span class="title">
        {{ $t("evalmee.home.student.examToAnswer.title") }}
      </span>

      <div
        v-if="quizzesAttemptSummariesToAnswerVisible.length === 0"
        class="subtitle-2 center grey--text my-10"
      >
        {{ $t("evalmee.home.student.examToAnswer.noExam") }}
      </div>
      <template v-else>
        <v-sheet
          v-for="(quizzesAttemptSummariesGroup, index) in quizzesAttemptSummariesToAnswerGrouped"
          :key="`quizzesAttemptSummaryGroup-${index}`"
          color="transparent"
          class="mt-5"
          id="scroll_content"
        >
          <div class="mb-1">
            <span class="subtitle-2">
              {{ quizzesAttemptSummariesGroup.name }}
            </span>
          </div>
          <v-card
            flat
            outlined
          >
            <quizzes-attempt-summaries-item-evalmee
              v-for="(quizzesAttemptSummary, quizzesAttemptSummaryIndex) in quizzesAttemptSummariesGroup.sessions"
              :key="`quizzesAttemptSummary-${quizzesAttemptSummaryIndex}`"
              :quizzes-attempt-summary="quizzesAttemptSummary"
            />
          </v-card>
        </v-sheet>
      </template>
    </template>

    <template v-if="quizzesAttemptSummariesAnsweredAndOver.length > 0">
      <v-divider class="my-10" />
      <span class="title">
        {{ $t("evalmee.home.student.examAnsweredAndOver") }}
      </span>

      <v-sheet
        v-for="(quizzesAttemptSummariesGroup, index) in quizzesAttemptSummariesAnsweredAndOverGroupedByDate"
        :key="`quizzesAttemptSummaryGroupff-${index}`"
        color="transparent"
        class="mt-5"
        id="scroll_content"
      >
        <div class="mb-1">
          <span class="subtitle-2">
            {{ quizzesAttemptSummariesGroup.name }}
          </span>
        </div>
        <v-card
          flat
          outlined
        >
          <quizzes-attempt-summaries-item-evalmee
            v-for="(quizzesAttemptSummary, quizzesAttemptSummaryIndex) in quizzesAttemptSummariesGroup.sessions"
            :key="`quizzesAttemptSummary-${quizzesAttemptSummaryIndex}`"
            :quizzes-attempt-summary="quizzesAttemptSummary"
          />
        </v-card>
      </v-sheet>
    </template>

    <student-home-footer class="mt-10" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import arrayHelpers from "../../helpers/array_helpers"
import stringHelpers from "../../helpers/string_helpers"
import chatSupportHelpers from "@/helpers/chat_support_helpers"
import StudentHomeFooter from "@/views/evalmee_student/StudentHomeFooter.vue"
import QuizzesAttemptSummariesItemEvalmee from "@/components/quiz/quiz_attempt_summary_item_evalmee.vue"

export default {
  name: "EvalmeeStudentHome",
  data() {
    return {
      dateTimeFormat: "DD/MM/YYYY H:mm",
      dateFormat: "DD/MM/YYYY",
      quizzesLoading: true,
    }
  },
  components: {
    QuizzesAttemptSummariesItemEvalmee,
    StudentHomeFooter,
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "allSchoolsQuizzes",
      "scoreByQuizAndUserId",
      "quizzesAttemptSummaries",
    ]),
    quizzesAttemptSummariesToAnswerVisible() {
      const hideLtiManagedNotAnswered = (q) => !q.quiz_lti_managed || q.is_answered

      return this.quizzesAttemptSummaries
        .filter(hideLtiManagedNotAnswered)
        .filter(
          q => ["not_answerable_yet","answering", "answering_in_another_session", "to_answer"].includes(q.answerability)
        )
    },

    quizzesAttemptSummariesAnsweredAndOver() {
      return this.quizzesAttemptSummaries
        .filter(q => ["answered", "answered_in_another_session", "ended_and_not_answered"].includes(q.answerability))
        .sort((a, b) => {
          return new Date(a.sortable_date) - new Date(b.sortable_date)
        })
    },

    quizzesAttemptSummariesToAnswerGrouped() {
      return [
        this.quizzesAttemptSummariesGroupWithoutDate,
        ...this.quizzesAttemptSummariesToAnswerGroupedByDate,
      ].filter(Boolean)
    },

    quizzesAttemptSummariesGroupWithoutDate() {
      const quizzesAttemptWithoutDate = this.quizzesAttemptSummariesToAnswerVisible.filter((s) => !s.sortable_date)

      if (quizzesAttemptWithoutDate.length === 0) return null

      return {
        name: this.$t("evalmee.home.student.nodate"),
        sessions: quizzesAttemptWithoutDate,
      }
    },

    quizzesAttemptSummariesToAnswerGroupedByDate() {
      return this.groupQuizzesAttemptSummariesByDate(this.quizzesAttemptSummariesToAnswerVisible)
    },
    quizzesAttemptSummariesAnsweredAndOverGroupedByDate() {
      return this.groupQuizzesAttemptSummariesByDate(this.quizzesAttemptSummariesAnsweredAndOver)
    },

    ltiNames() {
      const names = this.quizzesAttemptSummaries.map((q) => q.quiz_lti_provider_name).filter(Boolean)
      return [...new Set(names)].join(", ")
    },

    hasHiddenLtiQuizzes() {
      return this.quizzesAttemptSummaries.some(q => q.quiz_lti_managed)
    },
  },
  methods: {
    ...mapActions([
      "fetchQuizzesAttemptSummaries",
    ]),
    openSupportChat() {
      chatSupportHelpers.showCrispChat()
    },
    groupQuizzesAttemptSummariesByDate(quizzesAttemptSummaries) {
      let sortedQuizzesAttemptSummaries = quizzesAttemptSummaries
        .filter((s) => s.sortable_date)
        .sort((a, b) => {
          return new Date(a.sortable_date) - new Date(b.sortable_date)
        })
        .map((s) => {
          return { ...s, ...{ start_day: s?.sortable_date?.split("T")[0] } }
        })
      let grouped = arrayHelpers.arrayGroupBy(sortedQuizzesAttemptSummaries, "start_day")

      return Object.keys(grouped)
        .reverse()
        .map((group) => {
          // moment.js allows filtering for the format of string but here it does not seem to be working properly
          // So GEt the first element of the group
          const groupName = this.$moment(group).calendar({
            lastDay:
              "[" + this.$t("evalmee.home.student.examDate.yersteday") + "]",
            sameDay: "[" + this.$t("evalmee.home.student.examDate.today") + "]",
            nextDay:
              "[" + this.$t("evalmee.home.student.examDate.tommorrow") + "]",
            lastWeek: this.$t("evalmee.home.student.examDate.lastweek", {
              week: "dddd",
            }),
            nextWeek: "dddd DD MMM yyyy",
            sameElse: "dddd DD MMM yyyy",
          })

          return {
            name: stringHelpers.capitalize(groupName),
            sessions: grouped[group].sort((a, b) => {
              return new Date(a.start_at) - new Date(b.start_at)
            }),
          }
        })
        .filter((s) => s.sessions.length > 0)
    },
  },
  mounted() {
    this.fetchQuizzesAttemptSummaries({
      filter: { quiz: { quiz_type: "exam" } },
    }).then(() => {
      this.quizzesLoading = false
    })
  },
}
</script>

<style scoped></style>
