import { defaultWindow, useEventListener } from "@vueuse/core"
import { ref } from "vue"

export function useClipboardEvent() {
  const window = defaultWindow

  const copiedContent = ref("")
  const cutContent = ref("")
  const pastedContent = ref("")

  useEventListener(window.document, "copy", (event) => {
    copiedContent.value = getCopiedText(event)
  })

  useEventListener(window.document, "cut", (event) => {
    cutContent.value = getCopiedText(event)
  })

  useEventListener(window.document, "paste", (event) => {
    pastedContent.value = event.clipboardData.getData("text/plain")
  })

  const getCopiedText = (event) => {
    // clipboardData is only supported for input and textarea
    const clipboardData = event.clipboardData.getData("text/plain")
    const currentSelection = window.getSelection().toString()

    if( clipboardData.length > currentSelection.length ) {
      return clipboardData
    } else {
      return currentSelection
    }
  }

  return { copiedContent, cutContent, pastedContent }
}
