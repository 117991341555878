<template>
  <v-app-bar
    color="white"
    dense
    app
    clipped-left
    clipped-right
    flat
    class="border-bottom-divider"
  >
    <v-btn
      icon
      v-if="showBackButton"
      :to="backButtonTo"
      exact
    >
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <div>
      <img
        v-if="school?.logo"
        :src="school.logo"
        height="24"
        alt="School logo"
        class="school_logo mr-2"
      >
      <img
        v-else
        alt="Evalmee"
        class="evalmee-logo mr-2"
        src="@/assets/images/favicon/evalmee/android-chrome-192x192.png"
      >
    </div>

    <eva-button
      v-if="showToggleDrawer"
      @click.stop="toggleDrawer"
      text
      icon
      v-tooltip="{
        content: $t('live.questionDrawer.tooltip.collapse'),
        delay: 500,
      }"
    >
      <v-icon> {{ drawerToggleIcon }} </v-icon>
    </eva-button>



    <v-toolbar-title class="subtitle-2">
      {{ titlePrepend }}
      <quiz-student-nav-title
        v-if="quiz && quizzesAttemptSummary"
        :quiz="quiz"
        :quizzes-attempt-summary="quizzesAttemptSummary"
      />
      {{ titleAppend }}
      <quiz-state-badge
        :quiz="quiz"
        v-if="quiz && quiz.state === 'preview'"
      />
    </v-toolbar-title>

    <portal-target name="countdown" />

    <portal-target name="examinfo" />

    <div class="flex-grow-1" />
    <v-btn
      v-if="!isFullscreen"
      @click="goFullscreen"
      small
      text
      color="primary"
      class="mr-2"
    >
      <v-icon
        :left="this.$vuetify.breakpoint.name !== 'xs'"
      >
        fullscreen
      </v-icon>
      <span v-if="this.$vuetify.breakpoint.name !== 'xs'">
        {{ this.$t('live.fullscreen') }}
      </span>
    </v-btn>
    <student-help-menu
      v-if="quiz && quiz.quiz_type === 'exam'"
      :quiz="quiz"
      :outlined="false"
    />

    <portal-target name="leaveExam" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
import StudentHelpMenu from "../../../components/quiz/evalmee/student_help_menu.vue"
import QuizStudentNavTitle from "../../quizzes/quiz_student_nav_title.vue"
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import QuizStateBadge from "../../../components/quiz/quiz_state_badge.vue"

export default {
  name: "EvalmeeExamStudentNav",
  components: { QuizStateBadge, QuizStudentNavTitle, StudentHelpMenu },
  mixins: [quizStudentMixin],
  props: {
    quizId: {
      type: Number,
      default: null,
    },
    quizzesAttemptSummaryId: {
      type: Number,
      default: null,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backButtonTo: {
      type: Object,
      default: () => ({ name: "examsIndex" }),
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    titleAppend: {
      type: String,
      default: null,
    },
    titlePrepend: {
      type: String,
      default: null,
    },
    showToggleDrawer: {
      type: Boolean,
      default: false,
    },
    miniDrawer: {
      type: Boolean,
      default: false,
    },
    permanentDrawer: {
      type: Boolean,
      default: false,
    },
    drawerIsVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["goFullscreen", "toggleDrawer"],
  computed: {
    ...mapGetters(["quizById", "schools"]),
    quiz() { return this.quizById(this.quizId) },
    school() {
      return this.schools.find(school => school.id === this.quiz?.school_id)
    },
    drawerToggleIcon() {
      if(!this.drawerIsVisible) return "mdi-menu"

      return this.permanentDrawer ? "mdi-menu-open" : "mdi-menu-close"
    },
  },
  methods: {
    goFullscreen() {
      this.$emit("goFullscreen")
    },
    toggleDrawer() {
      this.$emit("toggleDrawer")
    },

  },
}
</script>

<style scoped>
  .school_logo {
    max-height: 80px;
    display: flex;
    align-items: center;
  }

  .evalmee-logo {
  height: 24px;
  display: flex;
  align-items: center;
  }
</style>
