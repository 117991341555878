<template>
  <div>
    <v-text-field
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      :label="$t('user.passwordInput.password.label')"
      background-color="accentLight"
      name="user[password]"
      v-bind:value="password"
      v-on:input="$emit('update:password', $event)"
      flat
      :placeholder="$t('user.passwordInput.password.label')"
      :rules="[rules.required, rules.passwordMin]"
      filled
      solo
      @click:append="showPassword = !showPassword"
      validate-on-blur
    ></v-text-field>

    <v-text-field
      v-if="withConfirmation"
      :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPasswordConfirmation ? 'text' : 'password'"
      :label="$t('user.passwordInput.confirmation.label')"
      background-color="accentLight"
      name="user[password_confirmation]"
      v-bind:value="passwordConfirmation"
      v-on:input="$emit('update:passwordConfirmation', $event)"
      flat
      :placeholder="$t('user.passwordInput.confirmation.label')"
      :rules="[rules.required, rules.passwordMatch]"
      filled
      solo
      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      validate-on-blur
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "passwordInput",
  props: {
    password: String,
    passwordConfirmation: String,
    withConfirmation: Boolean,
  },
  data() {
    return {
      showPassword: false,
      showPasswordConfirmation: false,
      rules: {
        required: value => !!value || this.$t("user.userLogin.required"),
        passwordMin: (value) => value.length >= 8 || this.$t("user.edit.passwordMin"),
        passwordMatch: () => (this.password === this.passwordConfirmation) || this.$t("user.edit.passwordMatch"),
      },
    }
  },
}
</script>

<style scoped>

</style>
