<template>
  <div>
    <div
      v-html="$t('live.examOnboarding.conditions.fullScreen')"
      v-if="quiz.require_fullscreen"
    />
    <div
      v-if="quiz.detect_mouse_leave"
      v-html="$t('live.examOnboarding.conditions.cheatingContent')"
    />

    <div v-if="quiz.similarity_tool_enabled && quizzesAttemptSummary">
      <br>
      <div v-html="this.$t('live.examOnboarding.agreement.similarityDescription', {toolName: quiz.similarity_tool_name})" />

      <a
        :href="quiz.links.similarity_tool_eula"
        target="_blank"
      >
        {{ $t("live.examOnboarding.agreement.similarityToolEulaLink", {toolName: quiz.similarity_tool_name}) }}
      </a>
      <v-checkbox
        :label="$t('live.examOnboarding.agreement.agreementSimilarity', {toolName: quiz.similarity_tool_name})"
        v-model="tempQuizzesAttemptSummary.similarity_tool_eula_accepted_at"
      />
    </div>

    <div v-if="this.quiz.video_proctoring">
      <br>
      <div v-html="this.$t('live.examOnboarding.proctoring')" />
    </div>

    <br>

    <div v-if="quizzesAttemptSummary">
      <div v-if="quiz.video_proctoring">
        <v-checkbox
          :label="$t('live.examOnboarding.agreement.agreementData')"
          v-model="tempQuizzesAttemptSummary.user_data_usage_accepted_at"
        />
      </div>
      <br>
    </div>
  </div>
</template>

<script>

export default {
  name: "QuizOnboardingUserDataAgreement",
  props: {
    quiz: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  emits: ["update:quizzes-attempt-summary"],
  computed: {
    tempQuizzesAttemptSummary: {
      get() { return this.quizzesAttemptSummary},
      set(val) { this.$emit("update:quizzes-attempt-summary", val) },
    },
  },
}
</script>

<style scoped>

</style>
