<template>
  <v-navigation-drawer
    v-model="drawerIsVisible"
    v-if="quiz && quizInstance"
    app
    clipped
    :permanent="!hasMobileDrawer"
    floating
    :expand-on-hover="!permanentlyExpendedDrawer && !hasMobileDrawer"
    :mini-variant.sync="miniDrawerTemp"
    class="questions-list-drawer parent-to-hover border-right-divider flat"
    ref="drawer"
    :class="{
      'mini-drawer': miniDrawerTemp,
      'mobile-drawer': hasMobileDrawer,
    }"
    :width="drawerWidth"
    bottom
  >
    <template #prepend>
      <template v-if="!miniDrawerTemp">
        <v-list>
          <questions-filter
            v-if="quiz.allow_questions_navigation"
            class="mx-2"
            v-model="filter"
            :questions="questions"
          />
          <template v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ questionsCount }} Questions
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ questionsCount - answeredQuestionsCount }} {{ $tc('live.questionDrawer.notAnswered',answeredQuestionsCount) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
        </v-list>
      </template>
    </template>

    <v-list-item-group
      v-model="tempCurrentExerciseId"
      mandatory
    >
      <v-expansion-panels
        v-model="openedSectionPanel"
        accordion
        flat
        mandatory
        v-if="sections.length > 1"
        class="sections-panel"
      >
        <v-expansion-panel
          v-for="(section, index) in filteredSections"
          :key="section.id"
          :value="section.id"
        >
          <v-expansion-panel-header
            :hide-actions="miniDrawerTemp"
            class="header-overable px-2"
          >
            <navigation-item
              :title="section.attributes.name"
              :subtitle-left="`${ section.questionsCount } ${ $tc('live.questionDrawer.questions', section.questionsCount) }`"
              :subtitle-right="`Section ${ index + 1 }`"
              progress-bar
              :progress-percent="section.progressPercent"
              :is-list-item="false"
              :compact="miniDrawerTemp"
            >
              <template #icon>
                <v-icon small>
                  mdi-tab
                </v-icon>
              </template>
            </navigation-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            eager
            :style="`max-height: ${expansionPanelMaxHeight}px`"
            class="scrollable pr-0"
          >
            <quiz-student-exercises-navigation
              v-if="section.children"
              :current-exercise-id.sync="tempCurrentExerciseId"
              :exercises="section.children"
              :mini-drawer="miniDrawerTemp"
              :navigable="!!navigable"
              :filter="filter"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <quiz-student-exercises-navigation
        v-else
        :current-exercise-id.sync="tempCurrentExerciseId"
        :exercises="exercises"
        :mini-drawer="miniDrawerTemp"
        :navigable="!!navigable"
        :filter="filter"
      />
    </v-list-item-group>
  </v-navigation-drawer>
</template>

<script>
import instanceExerciseMixin from "../evalmee/instance_exercise_mixin"
import helpers from "../../../helpers"
import QuizStudentExercisesNavigation from "./quizStudentExercisesNavigation.vue"
import Vue from "vue"
import NavigationItem from "./navigationItem.vue"
import QuestionsFilter from "./questionsFilter.vue"

export default {
  name: "QuizStudentNavigation",
  mixins: [instanceExerciseMixin],
  components: { QuestionsFilter, NavigationItem, QuizStudentExercisesNavigation },
  props: {
    currentExercise: {
      type: Object,
      default: null,
    },
    quiz: {
      type: Object,
      default: null,
    },
    miniDrawer: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:currentExercise",
    "update:miniDrawer",
    "update:permanent-drawer",
    "update:drawer-is-visible",
  ],
  data: () => ({
    permanentlyExpendedDrawer: false,
    openedSectionPanel: 0,
    expansionPanelMaxHeight: 100,
    drawerIsVisible: false,
  }),
  computed: {
    tempCurrentExerciseId: {
      get() { return this.currentExercise?.id },
      set(id) {
        if(!this.navigable) return
        this.emitChange(this.exercises.find(e => e.id === id))
      },
    },
    miniDrawerTemp: {
      get() {
        if(this.hasMobileDrawer) return false
        return this.miniDrawer
      },
      set(val) { this.$emit("update:miniDrawer", val) },
    },
    navigable() { return this.quiz?.allow_questions_navigation},
    hasMobileDrawer() { return !["md", "lg", "xl"].includes(this.$vuetify?.breakpoint?.name)},
    drawerWidth() {
      return this.permanentlyExpendedDrawer ? 256 : 300
    },
  },
  methods: {
    emitChange(val) {
      this.$emit("update:current-exercise", val)
    },
    removeHTML: helpers.removeHTML,
    setDrawerSize() {
      if (["md", "lg", "xl"].includes(this.$vuetify?.breakpoint?.name)) {
        this.miniDrawerTemp = false
        this.permanentlyExpendedDrawer = true
      }
    },
    togglePermanentDrawer(){
      this.drawerIsVisible = true
      this.permanentlyExpendedDrawer = !this.permanentlyExpendedDrawer
      this.miniDrawerTemp = !this.permanentlyExpendedDrawer
    },
    openedSectionPanelByExerciseId(id){
      if(!id) return

      this.openedSectionPanel = this
        .sections
        .indexOf(this.getSectionByExerciseId(id))
    },
    setExpansionPanelMaxHeight() {
      const drawerHeight = this.$refs.drawer?.$el?.clientHeight
      const sectionHeight = 64
      if(drawerHeight == null) return

      const maxHeight =
        drawerHeight
        - this.sections.length * sectionHeight
        - (this.miniDrawerTemp ? 40 : 90)

      this.expansionPanelMaxHeight = Math.max(maxHeight, (1.5 * sectionHeight))
    },
    toggleDrawer() {
      if(this.hasMobileDrawer) {
        this.drawerIsVisible = !this.drawerIsVisible
      } else
        this.togglePermanentDrawer()
    },
  },
  mounted() {
    this.setDrawerSize()
    window.addEventListener("resize", this.setExpansionPanelMaxHeight)
    this.$root.$on("toggleDrawer", this.toggleDrawer)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setExpansionPanelMaxHeight)
  },
  watch: {
    currentExercise: {
      handler(val) {
        let el = document.getElementById(`nav_exercise_${val?.id}`)
        el?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

        this.openedSectionPanelByExerciseId(val?.id)
      },
      immediate: true,
    },
    sections: {
      handler() {
        Vue.nextTick(this.setExpansionPanelMaxHeight)
      },
      immediate: true,
    },
    miniDrawerTemp: {
      handler() {
        Vue.nextTick(this.setExpansionPanelMaxHeight)
      },
    },
    permanentlyExpendedDrawer: {
      handler(val) {
        this.$emit("update:permanent-drawer", val)
      },
      immediate: true,
    },
    drawerIsVisible: {
      handler(val) {
        this.$emit("update:drawer-is-visible", val)
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.mobile-drawer{
  /* used in mobile view only */
  max-height: 80% !important;
}
.scrollable {
  overflow: auto;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}
.sections-panel::v-deep .v-expansion-panel-header {
  padding-left: 0;
  padding-right: 8px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sections-panel::v-deep .v-expansion-panel-content {
  border: none !important;
}

.mini-drawer .sections-panel::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}

.sections-panel::v-deep .v-expansion-panel-content__wrap {
  padding-left: 16px;
  padding-right: 16px;
}

.header-overable:hover::before{
  background-color: #056FEB;
  opacity: 0.04;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
}
</style>

