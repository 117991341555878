<template>
  <div>
    <v-btn
      @click="$refs.input.click()"
    >
      {{ $t('live.question.open.upload.common.sendAPicture') }}
    </v-btn>  
    <v-sheet
      v-show="false"
      rounded
      class="drop-zone pa-2"
      color="gray darken-2"
      elevation="1"
      :class="{dragging: isDragging}"
      @dragover.prevent="dragover"
      @dragenter.prevent="dragover"
      @drop.prevent.stop="onDrop"
      @dragleave.prevent="dragleave"
    >
      <div
        :class="{ hidden: uploadInProgress }"
        @click="openFileBrowser"
      >
        <slot><i>Click to upload</i></slot>
      </div>
      <input
        accept="image/*"
        type="file"
        :multiple="multiple"
        ref="input"
        style="display: none"
        :disabled="disabled"
      >

      <div
        class="list-group"
        v-if="uploads.length &gt; 0"
      >
        <div
          class="list-group-item"
          v-for="file in uploads"
        >
          <slot
            name="progress"
            :file="file"
            :progress="progress"
          >
            <v-progress-circular
              size="20"
              indeterminate
            />
            Uploading: {{ file.file.filename }}<br>
          </slot>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { DirectUpload } from "activestorage"
export default {
  name: "DirectUpload",
  components: {
  },
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      progress: 0,
      uploads: [],
    }
  },
  mounted() {
    this.input.addEventListener("change", (_event) => {
      Array.from(this.input.files).forEach(file => this.upload(file))
      this.input.value = null
    })
  },
  methods: {
    // callbacks from active storage
    directUploadWillStoreFileWithXHR(xhr) {
      xhr.upload.addEventListener("progress", event => this.onProgress(event))
    },
    directUploadWillStoreFileWithXHR(xhr) {
      this.lastXhr = xhr
    },
    dragover() {
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    onDrop(event) {
      this.dragleave()
      const files = event.dataTransfer.files
      Array.from(files).forEach(file => this.upload(file))
    },
    onProgress(event) {
      this.progress = (event.loaded / event.total) * 100
    },
    upload(file) {
      const upload = new DirectUpload(file, this.uploadUrl, this)
      this.uploads.push({ file, upload })
      this.progress = 0
      upload.create((error, blob) => {
        if (error) {
          // TODO
        } else {
          this.uploads = this.uploads.filter(payload => payload.file.filename !== file.filename)
          this.$emit("upload", { file, blob })
        }
      })
    },
    openFileBrowser() {
      this.input.click()
    },
  },
  computed: {
    uploadInProgress() { return this.uploads.length > 0 },
    input() {
      return this.$refs.input
    },
  },
}
</script>

<style lang='scss' scoped>
.drop-zone {
  min-height: 50px;
}
.drop-zone.dragging {
  border: 3px dashed #444;
  position: relative;
}
.drop-zone.dragging:before {
  content: "Drop file here";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  color: #555;
}
input[type=file][data-direct-upload-url][disabled] {
  display: none;
}
</style>
