<template>
  <eva-button
    @click="button.action"
    :primary="primary"
    :disabled="disabled"
  >
    <v-progress-circular
      v-if="this.button.loading"
      indeterminate
      size="24"
      class="mr-2"
    />
    {{ label }}
  </eva-button>
</template>

<script>
export default {
  name: "FinishExamBtn",
  props: {
    buttons: { type: Array, required: true },
    button: { type: Object, required: true },
  },
  computed: {
    primary() {
      if(this.buttons.length === 1) return true

      return this.button.primary
    },
    disabled() {
      return this.button.loading
        || this.button.disabled
    },
    label() {
      if( this.button.loading ) return this.button.labelLoading

      return this.button.label
    },
  },
}
</script>
