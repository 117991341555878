<template>
  <div>
    <v-progress-circular
      v-if="state === 'unanswered'"
      :size="small ? 16 : 24"
      :width="2"
      :rotate="-90"
      color="primary"
      :value="progressPercent"
    />
    <v-icon
      v-else
      :color="color"
      :small="small"
      v-html="icon"
    />
  </div>
</template>


<script>
import { states } from "./exerciseStateHelpers"

export default {
  name: "QuestionStateIcon",
  props: {
    state: {
      type: String,
      required: true,
      validate: (value) => ["answered", "unanswered", "skipped", "all"].includes(value),
    },
    small: {
      type: Boolean,
      default: false,
    },
    progressPercent: {
      type: Number,
      default: null,
      validate: (value) => value >= 0 && value <= 100,
    },
  },

  computed: {
    currentState() {
      return states.find((s) => s.name === this.state)
    },
    icon() {
      return this.currentState.icon
    },
    color() {
      return this.currentState.color
    },

  },
}
</script>

