<template>
  <v-card
    outlined
    flat
  >
    <div
      v-for="availableAttempt in attemptSummariesForSameQuiz"
      :key="availableAttempt.id"
    >
      <v-list-item
        :to="{
          name: 'onboarding',
          params: { quizId: quiz.id, quizzesAttemptSummaryId: availableAttempt.id },
        }"
        :disabled="isDisabled(availableAttempt)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ availableAttempt.session_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ dateRange(availableAttempt.start_at, availableAttempt.end_at) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <quizzes-attempt-summary-state-student
            :quizzes-attempt-summary="availableAttempt"
          />
        </v-list-item-action>
      </v-list-item>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex"
import QuizzesAttemptSummaryStateStudent from "@/components/quiz/quizzesAttemptsSummaryStateStudent.vue"
import timeHelpers from "@/helpers/time_helpers"

export default {
  name: "SameQuizSessionsList",
  components: { QuizzesAttemptSummaryStateStudent },
  props: {
    quiz: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["quizzesAttemptSummaries"]),

    attemptSummariesForSameQuiz() {
      return this.quizzesAttemptSummaries
        .filter(s => s.quiz_id === this.quiz.id )
        .sort((a, b) => a.sortable_date - b.sortable_date)
    },
    isDisabled() {
      return quizzesAttemptSummary => [
        "answering_in_another_session",
        "answered_in_another_session",
      ].includes(quizzesAttemptSummary.answerability)
    },
  },
  methods: {
    dateRange(start, end) {
      return timeHelpers.formatDateRange(start, end)
    },
  },
}
</script>

