<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <v-container
        fill-height
        fluid
        tag="v-card"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col>
            <v-card
              style="margin: auto"
              max-width="500"
            >
              <v-card-title>
                {{ $t('evalmee.wrongDateTimeDialog.title') }}
              </v-card-title>
              <v-card-text>
                <div v-html="$t('evalmee.wrongDateTimeDialog.content')" />
              </v-card-text>
              <v-card-actions>
                <student-help-menu :outlined="false" />
                <div class="flex-grow-1" />

                <v-btn
                  text
                  @click="close"
                >
                  {{ $t('evalmee.wrongDateTimeDialog.skip') }}
                </v-btn>
                <v-btn
                  @click="getServerDate"
                  text
                  color="primary"
                >
                  {{ $t('evalmee.wrongDateTimeDialog.retry') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"
import StudentHelpMenu from "./student_help_menu.vue"

const timeThreshold = 30000 //ms

export default {
  name: "DateTimeCheck",
  components: { StudentHelpMenu },
  data: () => ({
    serverTimestamp: 0,
    clientTimestamp: 0,
    dialog: false,
  }),
  methods: {
    setTimestamps(serverTimeStamp) {
      this.serverTimestamp = serverTimeStamp
      this.clientTimestamp = Date.now()
    },
    getServerDate() {
      axios.get("api/v1/date_time")
        .then(response => this.setTimestamps(response.data.timestamp))
    },
    close() {
      this.dialog = false
    },
  },
  computed: {
    difference() {
      return this.clientTimestamp - this.serverTimestamp
    },
  },
  mounted() {
    this.getServerDate()
  },
  watch: {
    difference(difference) {
      console.log({
        serverTime: this.serverTimestamp,
        clientTime: this.clientTimestamp,
        difference: difference,
      })
      this.dialog = Math.abs(difference) > timeThreshold

    },
  },
}
</script>

<style scoped>

</style>
