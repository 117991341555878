<template>
  <v-list-item
    :to="route"
    three-line
  >
    <v-list-item-icon class="mr-4">
      <v-icon>mdi-ballot-outline</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>
        {{ quizzesAttemptSummary.quiz_name }}
        <span
          v-if="quizzesAttemptSummary.session_name"
          class="grey--text"
        >
          | {{ quizzesAttemptSummary.session_name }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <quiz-tag-chip
          v-for="tag in orderedTagsByParent(quizzesAttemptSummary.quiz_tag_ids).filter(Boolean)"
          :key="`${quizzesAttemptSummary.quiz_id}_${tag && tag.id}`"
          :tag="tag"
          class="mr-1"
        />
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="d-flex"
        style="gap: 4px"
      >
        <evalmee-chip
          outlined
          small
          icon="mdi-clock-outline"
          v-if="quizzesAttemptSummary.start_at"
          color="blue-grey"
        >
          {{ $moment(quizzesAttemptSummary.start_at).format("HH[h]mm") }}
        </evalmee-chip>

        <evalmee-chip
          outlined
          small
          icon="mdi-timer-outline"
          v-if="quizzesAttemptSummary.time_limit_with_accommodation"
          color="blue-grey"
        >
          {{ totalQuizTime }}
        </evalmee-chip>

        <evalmee-chip
          outlined
          small
          icon="mdi-school-outline"
          v-if="schools.length > 1"
          color="blue-grey"
        >
          {{ quizzesAttemptSummary.school_name }}
        </evalmee-chip>

        <evalmee-chip
          outlined
          small
          icon="mdi-check-circle-outline"
          v-if="showStartedFinishedAt"
          color="green"
        >
          {{ studentStartedFinishedAt }}
        </evalmee-chip>

        <time-accommodation-chip
          v-if="quizzesAttemptSummary.time_accommodation_id"
          :time-accommodation="accommodation"
        />
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <quizzes-attempt-summary-state-student
        :quizzes-attempt-summary="quizzesAttemptSummary"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex"
import QuizTagChip from "./evalmee/quiz_tag/quiz_tag_chip.vue"
import quizScoreStudentMixin from "../../views/quizzes/quiz_score_student_mixin"
import timeHelpers from "@/helpers/time_helpers"
import QuizzesAttemptSummaryStateStudent from "@/components/quiz/quizzesAttemptsSummaryStateStudent.vue"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"
import TimeAccommodationChip from "@/components/quiz/time_accommodations/TimeAccommodationChip.vue"

export default {
  name: "QuizzesAttemptSummariesItemEvalmee",
  props: {
    quizzesAttemptSummary: { type: Object, required: true },
  },
  mixins: [quizScoreStudentMixin],
  components: { TimeAccommodationChip, EvalmeeChip, QuizzesAttemptSummaryStateStudent, QuizTagChip },
  computed: {
    ...mapGetters([
      "scoreByQuizAndUserId",
      "currentUser",
      "orderedTagsByParent",
      "schools",
      "timeAccommodation",
    ]),
    route() {
      if (this.quizzesAttemptSummary.answerability === "answered") {
        return this.scoreRoute
      } else {
        return this.sessionsRoute
      }
    },
    score() {
      return this.scoreByQuizAndUserId(this.quizzesAttemptSummary.quiz_id, this.currentUser.id)
    },
    scoreRoute() {
      return {
        name: "score",
        params: { quizId: this.quizzesAttemptSummary.quiz_id, quizzesAttemptSummaryId: this.quizzesAttemptSummary.id },
      }
    },
    sessionsRoute() {
      return {
        name: "sessionsSelection",
        params: { quizId: this.quizzesAttemptSummary.quiz_id, quizzesAttemptSummaryId: this.quizzesAttemptSummary.id },
      }
    },
    totalQuizTime: function () {
      return timeHelpers.formatDuration(this.quizzesAttemptSummary.time_limit_with_accommodation * 1000)
    },
    studentStartedFinishedAt() {
      return timeHelpers.formatDateRange(this.quizzesAttemptSummary.student_started_at, this.quizzesAttemptSummary.student_finished_at)
    },
    showStartedFinishedAt() {
      return this.quizzesAttemptSummary.student_started_at && this.quizzesAttemptSummary.student_finished_at
    },
    accommodation() {
      return this.timeAccommodation(this.quizzesAttemptSummary.time_accommodation_id)
    },
  },
}
</script>

<style scoped>
.collection a.collection-item {
  color: #757575;
}
</style>
