<template>
  <v-chip
    :color="color"
    dark
    x-small
    label
    class="pl-1 pr-1 status_chip">
    <v-icon
      v-if="recording"
      x-small
      class="mr-1"
    >
      fiber_manual_record
    </v-icon>
    {{ label }}
  </v-chip>
</template>

<script>
export default {
  name: "RecordingIndicator",
  props: {
    recording: { type: Boolean, default: false },
  },
  computed: {
    label() {
      return this.recording ?
        this.$t("evalmee.proctoring.state.recording") :
        this.$t("evalmee.proctoring.state.notRecording")
    },
    color() {
      return this.recording ? "red" : "grey darken-4"
    },
  },
}
</script>

<style scoped>
 .status_chip{
   font-weight: bold;
 }
</style>
