import { mapActions, mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters([
      "quizInstanceContentByQuizId",
      "quizInstanceByQuizId2",
      "currentUser",
      "instancesLoading",
      "answeredQuestionsInInstances",
      "quizInstanceQuestionsByQuizId",
      "questionFilter",
    ]),

    filter: {
      get() { return this.questionFilter },
      set(value) { this.updateQuestionFilter(value) },
    },

    quizInstance() { return this.quizInstanceByQuizId2(this.quiz?.id, this.currentUser.id) },

    exercises() {
      if(!this.quizInstanceContentByQuizId(this.quiz?.id, this.currentUser?.id)?.children) return []
      return this.quizInstanceContentByQuizId(this.quiz?.id, this.currentUser?.id)
        ?.children
        ?.flatMap( section => section?.children)
    },

    sections() {
      return this.quizInstanceContentByQuizId(this.quiz?.id, this.currentUser?.id)?.children || []
    },

    answeredQuestionsCount() {
      return this.answeredQuestionsInInstances(this.quiz?.id, this.currentUser?.id)?.length
    },

    questions() {
      return this.exercises.flatMap( exercise => exercise?.children)
    },

    questionsCount() {
      return this.sections.reduce((acc, section) => acc + section?.questionsCount, 0)
    },

    filteredSections() {
      switch(this.filter) {
      case "all":
        return this.sections
      case "answered":
        return this.sections.filter( section =>
          section?.exercises.some( exercise => exercise?.isAnswered) ||
          section?.exercises.find( exercise => exercise.id === this.currentExercise.id)
        )
      case "unanswered":
        return this.sections.filter( section =>
          section?.exercises.some( exercise => !exercise?.isAnswered) ||
        section?.exercises.find( exercise => exercise.id === this.currentExercise.id)

        )
      }
    },

    filteredExercises() {
      switch(this.filter) {
      case "all":
        return this.filteredSections.flatMap( section => section?.children)
      case "answered":
        return this.filteredSections.flatMap( section =>
          section?.children.filter( exercise => exercise?.isAnswered) ||
          section?.children.find( exercise => exercise.id === this.currentExercise.id)
        )
      case "unanswered":
        return this.filteredSections.flatMap( section =>
          section?.children.filter( exercise => !exercise?.isAnswered) ||
          section?.children.find( exercise => exercise.id === this.currentExercise.id)
        )
      }
    },
  },
  methods: {
    ...mapActions(["updateQuestionFilter"]),
    getSectionByExerciseId(id) {
      return this.sections.find( section => section.children.find( exercise => exercise.id === id))
    },
  },
}
