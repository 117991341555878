<template>
  <v-sheet
    @click.alt="debug = !debug"
    color="transparent"
  >
    <div
      v-html="$t('live.examOnboarding.screenShare.instructions')"
    />

    <div v-if="debug && (screenCount() > 0 || sharedScreenSurface())">
      <div class="mt-4">
        Type de partage: {{ displaySurfaceText }}
      </div>
      <div>
        Nombre d'écrans: {{ screenCount() }}
      </div>
    </div>
    <v-alert
      v-if="errorMessage"
      type="error"
      text
      dense
      icon="mdi-alert-outline"
      class="mt-4"
    >
      <span v-html="errorMessage" />
    </v-alert>

    <div class="mt-4 center">
      <eva-button
        v-if="showUpdateScreenShareButton"
        primary
        :label="$t('live.examOnboarding.screenShare.buttons.updateScreenShare')"
        @click="openScreenShareSettings"
      />

      <eva-button
        v-if="!sharedScreenSurface().value && !errorMessage"
        primary
        :label="$t('live.examOnboarding.screenShare.buttons.shareScreen')"
        @click="enableScreenShare"
      />
    </div>

    <div v-if="metSharingScreenRequirements">
      {{ $t("live.examOnboarding.screenShare.hideMessage") }}
    </div>
  </v-sheet>
</template>

<script>
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useDeviceInfo } from "@/composables/useDeviceInfo"

export default {
  name: "QuizOnboardingScreenShare",
  props: {
    quizId: {
      type: Number,
      required: true,
    },
    quizzesAttemptSummaryId: {
      type: Number,
      required: true,
    },
  },
  emits: ["enable-screen-share", "open-screen-share-settings", "next-step"],
  data() {
    return {
      debug: false,
    }
  },
  setup(props) {
    const { screenCount, sharedScreenSurface, screenSharingError } = useStudentEventRegisterer(
      props.quizId,
      props.quizzesAttemptSummaryId
    )

    return {
      screenCount,
      sharedScreenSurface,
      screenSharingError,
    }
  },
  computed: {
    displaySurfaceText() {
      const surfaceType = this.sharedScreenSurface().value
      switch (surfaceType) {
      case "monitor":
        return "Screen"
      case "window":
        return "Window"
      case "browser":
        return "Browser tab"
      default:
        return "None"
      }
    },

    metSharingScreenRequirements() {
      return useStudentEventRegisterer(null, null).metSharingScreenRequirements.value
    },
    showUpdateScreenShareButton() {
      if(this.errorMessage) return true
      if(this.screenCount().value > 1 ) return true
      return this.sharedScreenSurface().value  && this.sharedScreenSurface().value !== "monitor"
    },
    errorMessage() {
      const { deviceDetails, browserInfo } = useDeviceInfo()
      // console.log("deviceDetails", deviceDetails)
      if (deviceDetails.device_type && deviceDetails.device_type !== "computer") {
        return this.$t("live.examOnboarding.screenShare.errors.deviceType")
      }
      if (this.screenCount().value > 1) {
        return this.$t("live.examOnboarding.screenShare.errors.screenCount")
      }
      if (this.sharedScreenSurface().value !== "monitor" && this.screenCount().value > 0) {
        return this.$t("live.examOnboarding.screenShare.errors.screenType")
      }
      if (this.screenSharingError().value === "screen_share_system_denied") {

        return this.$t("live.examOnboarding.screenShare.errors.screenSharingSystemError", { browserName: browserInfo.browser_name })
      }
      return null
    },
  },
  methods: {
    enableScreenShare() {
      this.$root.$emit("enable-screen-share")
    },
    openScreenShareSettings() {
      this.$root.$emit("open-screen-share-settings")
    },
  },
  watch: {
    metSharingScreenRequirements: {
      handler(val) {
        console.log("metSharingScreenRequirements", val)
        if(val) this.$emit("next-step")
      },
      immediate: true,
    },
  },
}
</script>
