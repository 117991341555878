// Entrypoint for the evalmee student app

window.global ||= window
// eslint-disable-next-line no-unused-vars
import Vue from "vue"
import "../config/vue_devtools.js"
import evalmeeStudentRouter from "../router/student_router_evalmee"
import store from "../store"
import axios from "axios"
import ws from "../web_sockets"
import VTooltip from "v-tooltip"
import "../assets/tooltips.scss"
import vuetify from "./../plugins/vuetify"
import confirmDialog from "vuetify-vuejs-confirmdialog"
import fullscreen from "vue-fullscreen"
import momentPlugin from "@/plugins/moment_plugin"
import i18n from "./../plugins/i18n"
import PortalVue from "portal-vue"
import "../helpers/old_browser_detection.js"
import trackingHelpers from "@/helpers/tracking_helpers"
import errorMonitoringHelpers from "@/helpers/error_monitoring_helpers"
import chatSupportHelpers from "../helpers/chat_support_helpers"
import "@/config/import_eva_components_globally.js"

chatSupportHelpers.initCrisp()

Vue.use(fullscreen)
Vue.use(VTooltip)
Vue.use(confirmDialog, { vuetify })
Vue.use(momentPlugin)

Vue.prototype.$http = axios
Vue.prototype.$cable = ws.cable
Vue.use(PortalVue)
errorMonitoringHelpers.initSentry()
trackingHelpers.setupPostHog(evalmeeStudentRouter)
trackingHelpers.setupFeatureBase()

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: "#app",
    router: evalmeeStudentRouter,
    store,
    i18n,
    vuetify,
  })

})
