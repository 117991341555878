<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("live.question.open.upload.mobile.scanAnotehrQRCode") }}
        </v-btn>
      </template>
      <v-card tile>
        <v-toolbar
          dark
          dense
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>QR code</v-toolbar-title>
        </v-toolbar>
        <div class="pa-2">
          {{ $t("live.question.open.upload.mobile.scanInstruction") }}
          <a @click="useCode = !useCode">
            {{ $t("live.question.open.upload.mobile.notWorking") }}
          </a>

          <div v-if="useCode">
            <span
              v-html="$t('live.question.open.upload.mobile.manualCodeTips')"
            />
            <v-text-field
              v-model="typedCode"
              solo
              placeholder="Question code"
            />
            <v-btn @click="goToTypedCode">
              Go
            </v-btn>
          </div>

          <!--          {{ qrcode }}-->
          <!--          <a :href="redirect">-->
          <!--            go to {{ redirect }}-->
          <!--          </a>-->
        </div>
        <qrcode-stream
          @decode="onDecode"
          v-if="!useCode"
        />
      </v-card>
    </v-dialog>

    <div class="pa-2">
      <div v-if="isClosed">
        {{ $t("live.question.open.upload.mobile.examClosed") }}
      </div>

      <v-alert
        type="warning"
        v-if="isScoreClosed && !isClosed"
      >
        {{ $t("live.question.open.upload.mobile.outOfTime") }}
      </v-alert>

      <div v-if="question">
        Question :
        <katex-md :expr="question.name" />

        <br>
        <br>

        <direct-upload
          :upload-url="api.activeStorageDirectUploadUrl()"
          @upload="uploadImage"
        />

        <answer-uploads-preview
          :answer="answer"
          v-if="answer"
        />

        <div v-if="showReloadTips">
          {{ $t("live.question.open.upload.mobile.reloadTips") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DirectUpload from "../../components/questions/direct_upload.vue"
import api from "../../api"
import AnswerUploadsPreview from "../../components/questions/answer_uploads_preview.vue"
import { QrcodeStream } from "vue-qrcode-reader"
import KatexMd from "../../components/katex-md.vue"
import { newToast } from "../../helpers/notifications_helper"
import quizStudentMixin from "../quizzes/quiz_student_mixin"
import instanceExerciseMixin from "../../components/quiz/evalmee/instance_exercise_mixin"

export default {
  name: "AnswerPhotoUpload",
  components: { KatexMd, AnswerUploadsPreview, DirectUpload, QrcodeStream },
  mixins: [quizStudentMixin, instanceExerciseMixin],
  props: {
    quizId: { type: Number, default: null },
    questionId: { type: Number, default: null },
  },
  data: () => ({
    qrcode: "",
    dialog: false,
    typedCode: "",
    useCode: false,
  }),
  computed: {
    ...mapGetters([
      "quizInstanceQuestionsByQuizId",
      "answerByQuestionAndUserId",
    ]),
    api() {
      return api
    },
    question() {
      return this.quizInstanceQuestionsByQuizId(
        this.quizId,
        this.currentUser?.id
      )?.find((q) => q.id === this.questionId)?.attributes
    },
    answer() {
      return this.answerByQuestionAndUserId(
        this.question?.id,
        this.currentUser?.id
      )
    },
    redirect() {
      return new URLSearchParams(this.qrcode).get("redirect")
    },
    showReloadTips() {
      return this.answer?.pictures?.length > 0
    },
    isScoreClosed() {
      if (!this.score) return false
      return this.score?.state !== "started"
    },
    isClosed() {
      if (!this.quiz) return false
      if (this.quiz.state !== "active") return true

      return this.score?.state === "finished"
    },
  },
  methods: {
    ...mapActions([
      "fetchMyInstanceByQuiz",
      "fetchAnswers",
      "createAnswer",
      "fetchQuiz",
      "fetchScoresByQuiz",
      "fetchQuizzesAttemptSummary",
    ]),
    uploadImage({ blob }) {
      this.createAnswer({
        quizzesAttemptSummary: this.quizzesAttemptSummary,
        question: this.question,
        content: this.answer?.content,
        picture: blob.signed_id,
        save: true,
      })
    },
    onDecode(val) {
      this.qrcode = val
      // if (this.redirect.length === 0) return
      if (this.currentUser.guest) {
        window.location.href = val
      } else {
        window.location.href = new URLSearchParams(val).get("redirect")
      }
      this.dialog = false
    },
    goToTypedCode() {
      const [qid, quizzesAttemptSummaryId, questionId] = this.typedCode.split("-")
      this.$router.push({
        name: "answerPictureUpload",
        params: { quizId: qid, questionId: questionId, quizzesAttemptSummaryId: quizzesAttemptSummaryId },
      })
      this.typedCode = ""
      this.dialog = false
      newToast(
        this.$t("live.question.open.upload.mobile.questionSwitched"),
        "success"
      )
    },
  },
  watch: {
    quizId: {
      handler() {
        if (this.quizId) {
          this.fetchQuiz(this.quizId)
        }
      },
      immediate: true,
    },
    quiz: {
      handler(quiz) {
        if (quiz?.id) {
          this.fetchScoresByQuiz(this.quiz)
          this.fetchAnswers({ quiz: this.quiz })
          this.fetchQuizzesAttemptSummary(this.quizzesAttemptSummaryId).then(() => {
            this.fetchMyInstanceByQuiz({
              quiz: this.quiz,
              quizzesAttemptSummary: this.quizzesAttemptSummary,
            })
          })
          this.dialog = false
        }
      },
      immediate: true,
    },
    question(question) {
      if (question) this.dialog = false
    },
  },
  mounted() {
    if (!this.quizId || !this.questionId) this.dialog = true
  },
}
</script>

<style scoped></style>
