<template>
  <v-card
    v-if="quiz.live_chat && dialog"
    class="student-chat"
    :width="width"
  >
    <messages
      :channel-id="channelId"
      :quiz="quiz"
      v-if="quiz"
    >
      <template #title>
        <v-card-title class="primary white--text">
          {{ $t('quiz.evalmeeChat.contactYourTeacher') }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click.native="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
    </messages>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import Messages from "./messages.vue"

export default {
  name: "StudentChat",
  components: {
    Messages,
  },
  props: {
    quiz: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters([
      "currentUser",
      "channels",
      "globalChannelByQuizId",
      "messages",
    ]),

    width () {
      switch (this.$vuetify.breakpoint.name) {
      case "xs": return this.$vuetify.breakpoint.width - 20
      case "sm": return 400
      case "md": return 450
      case "lg": return 500
      case "xl": return 600
      }
      return 220
    },
    channel() {
      return this.channels(this.quiz.id).find( c => c.user_id === this.currentUser?.id)
    },
    globalChannel() {
      return this.globalChannelByQuizId(this.quiz.id)
    },
    channelId() {
      return this.channel?.id
    },
    channelMessages() {
      if (this.channelId !== this.globalChannel?.id) {
        return this.messages(this.channelId)
          .concat(this.messages(this.globalChannel?.id)
            .map((message) => ({ ...message, isGlobal: true }))).sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
          })}
      return this.messages(this.channelId)
    },
  },
  methods: {
    ...mapActions([
      "fetchChannelsByQuiz",
      "createChannelsForQuiz",
    ]),
    fetchChannel() {
      this.fetchChannelsByQuiz(this.quiz)
    },
    createChannel() {
      return this.createChannelsForQuiz({ quiz: this.quiz, userId: this.currentUser.id })
    },
  },
  mounted() {
    this.fetchChannel()
    this.$root.$on("display-chat", () => {
      this.dialog = true
    })
  },
  watch: {
    "channelMessages.length": {
      // add Old and NewItem to prevent bug when teacher see a message from student and it open the modal
      handler(old, newItem) {
        if (old !== newItem)
          this.dialog = true
      },
      deep: true,
    },

  },
}
</script>


<style scoped>
  .student-chat {
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 99;
  }
</style>
