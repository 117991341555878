<template>
  <div
    v-if="school.logo"
  >
    <img
      :src="school.logo"
      alt="School logo"
      class="mt-10"
      height="28"
      contain
    >

    <p class="powered_text">
      {{ $t("evalmee.logo.title") }}
      <img
        src="@/assets/images/logo_evalmee_text.png"
        alt="Evalmee logo"
        class="evalmee_small_logo"
      >
    </p>
  </div>
  <div v-else>
    <img
      src="@/assets/images/logo_evalmee.png"
      alt="Evalmee logo"
      class="evalmee_logo"
    >
  </div>
</template>

<script>
export default {
  name: "LogoHomeStudent",
  props: {
    school: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
  .evalmee_small_logo {
    height: 7px;
    margin-left: 0.33em;
  }
  .powered_text {
    font-size: 9px;
    color: grey;
    display: flex;
    align-items: center;
  }
  .evalmee_logo {
    height: 30px;
    margin-right: 1em;
  }
</style>
