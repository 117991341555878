<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="timeOver"
    :overlay-opacity="timeOver ? 1 : undefined"
    :overlay-color="timeOver ? 'background' : undefined"
    scrollable
  >
    <v-card flat>
      <v-card-title class="headline">
        {{ title }}

        <div class="flex-grow-1" />

        <student-help-menu :quiz="quiz" />
      </v-card-title>

      <v-list class="mx-2">
        <v-list-item
          v-for="(card, index) in cards"
          :key="index"
        >
          <v-list-item-icon class="mr-4 my-2">
            <eva-big-icon
              :icon="card.icon"
              size="30"
              color="blue"
              class="center"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ card.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-text>
        <v-alert
          v-if="showRemainingQuestionsWarning"
          type="info"
          class="mt-4"
          text
        >
          {{ $t('live.exitDialog.remainingQuestions', {unansweredQuestionsCount}) }}
        </v-alert>

        <v-alert
          v-if="retrySaveRequired"
          type="warning"
          class="mt-2"
          text
        >
          <katex-md :expr="$t('live.exitDialog.connectionIssue.description')" />
        </v-alert>

        <div
          v-if="!retrySaveRequired"
          v-html="$t('live.exitDialog.text')"
          class="mt-4"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <finishExamBtn
          v-for="(button, index) in buttons"
          :key="`finish-dialog-action-${index}`"
          :button="button"
          :buttons="buttons"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import StudentHelpMenu from "../../../components/quiz/evalmee/student_help_menu.vue"
import KatexMd from "../../../components/katex-md.vue"
import FinishExamBtn from "./finish_exam_btn.vue"
import { useQuizzesAttemptSummary } from "@/composables/useQuizzesAttemptSummary"
import EvaBigIcon from "@/components/shared/eva-big-icon.vue"

export default {
  name: "FinishExamDialog",
  components: { EvaBigIcon, FinishExamBtn, KatexMd, StudentHelpMenu },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    timeOver: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    retrySaveRequired: false,
    sendingAnswers: false,
  }),
  computed: {
    ...mapGetters([
      "currentUser",
      "areAllAnswersSynchronized",
      "answeredQuestionsInInstances",
      "editedAnswers",
    ]),
    dialog: {
      get() { return this.value},
      set(val) {
        this.$emit("input", val)
      },
    },
    isSaving() {
      return !this.areAllAnswersSynchronized(
        this.quiz,
        this.currentUser?.id
      )
    },
    title() {
      if(this.retrySaveRequired) return this.$t("live.exitDialog.connectionIssue.title")
      if(this.timeOver) return this.$t("live.exitDialog.title.timeOver")
      return this.$t("live.exitDialog.title.manualExit")
    },
    showCancelButton() {
      if(this.timeOver) return
      if(this.retrySaveRequired) return

      return true
    },
    answeredQuestionsCount() {
      return this.answeredQuestionsInInstances(this.quiz?.id, this.currentUser?.id)?.length
    },
    questionsCount() { return this.quiz?.questions_count},
    showRemainingQuestionsWarning() {
      return !this.timeOver &&
        this.answeredQuestionsCount < this.quiz?.questions_count
    },
    remainingTimeFormatted() {
      return useQuizzesAttemptSummary(this.quizzesAttemptSummary).remainingTimeFormatted
    },
    unansweredQuestionsCount() {
      return this.quiz?.questions_count - this.answeredQuestionsCount
    },
    cards() {
      return [
        {
          icon: "mdi-cloud-check-outline",
          label: this.$t("live.exitDialog.pendingAnswerWillBeSaved"),
          visible: true,
        },
        {
          icon: "mdi-check-circle-outline",
          label: this.$t("live.exitDialog.answeredQuestions", { answeredQuestionsCount: this.answeredQuestionsCount, questionsCount: this.questionsCount }),
          visible: true,
        },
        {
          icon: "mdi-timer-outline",
          label: this.timeOver ?
            this.$t("live.exitDialog.timeOver") :
            this.$t("live.exitDialog.timeNotOver", { remainingTime: this.remainingTimeFormatted.value }),
          visible: this.quiz?.automatic_start || this.quiz?.time_limit,
        },
      ].filter(e => e.visible)
    },

    buttons() {
      return [
        {
          label:    this.$t("live.exitDialog.cancelText.reviewMyAnswers"),
          action:   this.cancel,
          primary:  false,
          disabled: false,
          visible:  this.showCancelButton
            && this.unansweredQuestionsCount === 0
            && this.quiz.allow_questions_navigation
            && !this.sendingAnswers,
        },
        {
          label:    this.$t("live.exitDialog.cancelText.answerReminingQuestions"),
          action:   this.cancel,
          primary:  false,
          disabled: false,
          visible:  this.showCancelButton
            && !this.sendingAnswers
            && (
              this.unansweredQuestionsCount > 0
              || !this.quiz.allow_questions_navigation
            ),
        },
        {
          label:        this.$t("live.exitDialog.confirmText"),
          labelLoading: this.$t("live.exitDialog.sendingMyAnswers"),
          action:       this.saveAndExit,
          primary:      true,
          disabled:     false,
          loading:      this.sendingAnswers,
          visible:      !this.retrySaveRequired,
        },
        {
          label:    this.$t("live.exitDialog.connectionIssue.retry"),
          labelLoading: this.$t("live.exitDialog.sendingMyAnswers"),
          action:   this.reSyncAnswers,
          primary:  true,
          disabled: false,
          loading:  this.sendingAnswers,
          visible:  this.retrySaveRequired,
        },
      ].filter(e => e.visible)
    },
  },
  methods: {
    ...mapActions([
      "retrySyncAnswers",
      "newAppNotification",
      "sendEditedAnswers",
    ]),
    cancel() {
      this.dialog = false
    },
    saveAndExit() {
      this.isSaving ? this.reSyncAnswers() : this.confirm()
    },
    confirm() {
      this.dialog = false
      this.$emit("confirm")
    },
    reSyncAnswers(){
      this.newAppNotification({
        message: this.$t("live.exitDialog.connectionIssue.pendingRetry"),
        type: "info",
      })
      this.sendingAnswers = true
      this
        .retrySyncAnswers({ quiz: this.quiz, quizzesAttemptSummary: this.quizzesAttemptSummary })
        .then(() => {
          this.newAppNotification({
            message: this.$t("live.exitDialog.connectionIssue.successRetry"),
            type: "success",
          })
          this.confirm()
        }).catch(() => {
          this.retrySaveRequired = true
          this.sendingAnswers = false
          this.newAppNotification({
            message: this.$t("live.exitDialog.connectionIssue.failledRetry"),
            type: "error",
          })

        })
    },
    sendAllAnswers(){
      if(this.editedAnswers.length === 0) return

      this.sendEditedAnswers({ quiz: this.quiz, quizzesAttemptSummary: this.quizzesAttemptSummary })
    },
  },
  watch: {
    dialog(val) {
      if(!val) return

      this.sendAllAnswers()
    },
  },
}
</script>

<style scoped>
  .full-height {
    display: flex;
    align-items: stretch;
  }

  .full-width {
    width: 100%;
  }
</style>

