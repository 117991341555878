<template>
  <div>
    <v-alert type="warning">
      {{ $t('live.examOnboarding.webcamTest.blockedWebcam') }}
    </v-alert>

    <v-card>
      <v-card-text> {{ helpText}} </v-card-text>

      <img
        v-if="helpImageUrl"
        :src="helpImageUrl"
        alt="browser help"
        class="browser_help_image"
      >

       <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            @click="retry"
            color="primary"
          >
            {{ $t('live.examOnboarding.webcamTest.retry') }}
          </v-btn>
       </v-card-actions>
    </v-card>


  </div>
</template>

<script>

import browserDetection from "../../../helpers/brower_detection"

export default {
  name: "disabledWebcamHelp",
  methods: {
    retry() {
      this.$emit("retry")
    },
  },
  computed: {
    isChromeLike() { return browserDetection.isChromeLike() },
    isFirefox()    { return browserDetection.isFirefox() },
    isSafari()     { return browserDetection.isSafari() },

    helpText() {
      return this.$t(`live.examOnboarding.webcamTest.webcamHelp.${browserDetection.mainBrowserMapping()}.text`)
    },
    helpImageUrl() {
      return this.$t(`live.examOnboarding.webcamTest.webcamHelp.${browserDetection.mainBrowserMapping()}.picture`)
    },
  },
}
</script>

<style scoped>
  .browser_help_image {
    width: 100%
  }

</style>
