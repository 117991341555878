<template>
  <div>
    {{ $t('live.examOnboarding.agreement.agreement') }}
    <br>

    <div v-if="quizzesAttemptSummary">
      <v-checkbox
        :label="$t('live.examOnboarding.agreement.agreementValidation')"
        v-model="tempQuizzesAttemptSummary.user_agreement_accepted_at"
        :hint="agreementHint"
        :persistent-hint="showAgreementDate"
        @change="saveQuizzesAttemptSummary"
      />
      <br>
    </div>

    {{ $t('live.examOnboarding.wait') }}
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "QuizOnboardingUserAgreement",
  props: {
    quizzesAttemptSummary: { type: Object, required: true },
  },
  emits: ["update:quizzes-attempt-summary", "save:quizzes-attempt-summary"],
  computed: {
    ...mapGetters(["currentUser"]),
    tempQuizzesAttemptSummary: {
      get() { return this.quizzesAttemptSummary},
      set(val) { this.$emit("update:quizzes-attempt-summary", val) },
    },
    agreementHint() {
      return `${this.$t("live.examOnboarding.agreement.agreedAt")} ${this.agreementDate}`
    },
    agreementDate() {
      if(!this.quizzesAttemptSummary?.user_agreement_accepted_at) return ""
      return this.$moment(this.quizzesAttemptSummary.user_agreement_accepted_at)
        .format("YYYY/MM/DD H:mm")
    },
    showAgreementDate() {
      // we have to wait for the api response with a date and not "true"
      return !!this.quizzesAttemptSummary.user_agreement_accepted_at && this.quizzesAttemptSummary.user_agreement_accepted_at !== true
    },
  },
  methods: {
    saveQuizzesAttemptSummary() {
      this.$emit("save:quizzes-attempt-summary")
    },
  },
}
</script>

<style scoped>

</style>
