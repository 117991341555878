<template>
  <div>
    <div
      v-if="cardContent.showScore"
      class="center-align mt-3 mb-6"
    >
      <div class="display-2 my-3">
        {{ grade }}/{{ quiz.grade_scale }}
      </div>
    </div>


    <success-level-student
      :grade="grade"
      :quiz="quiz"
    />
    <v-card
      max-width="600"
      style="margin: auto"
      flat
      outlined
    >
      <v-card-title>
        {{ $t("live.finalScore.finish.title") }}
      </v-card-title>

      <v-list>
        <v-list-item
          v-for="(item, index) in checklist"
          :key="`checklist-${index}`"
          @click="() => item.action ? item.action() : null"
        >
          <v-list-item-avatar
            v-if="item.success"
            size="24"
            color="green"
          >
            <v-icon
              v-text="'mdi-check-bold'"
              dark
              small
            />
          </v-list-item-avatar>
          <v-list-item-avatar
            v-if="item.loading"
            size="24"
          >
            <v-progress-circular
              indeterminate
              size="24"
              color="primary"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
            <v-list-item-subtitle v-text="item.subtitle" />
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-card-text>
        <v-alert
          v-if="this.proctoring && !this.proctoringDataSent"
          type="warning"
          dense
          text
          v-text="$t('live.finalScore.finish.dontClose')"
        />

        <span v-html="cardContent.text" />

        <div
          v-if="cardContent.buttons.showScanReview"
          class="mt-5"
        >
          <scan-modale
            :score="score"
            :quiz="quiz"
            color="primary"
            hide-subject-link
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <eva-button
          @click="retakeExam"
          v-if="cardContent.buttons.showRetake"
        >
          {{ $t('live.finalScore.retake') }}
        </eva-button>

        <v-spacer />

        <eva-button
          :to="correctionRoute"
          v-if="cardContent.buttons.showCorrection"
          color="primary"
        >
          {{ $t("live.getWholeCorrection") }}
        </eva-button>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ScanModale from "../paper/scan_modale.vue"
import { mapActions, mapGetters } from "vuex"
import SuccessLevelStudent from "./success_level_student.vue"

export default {
  name: "ScoreCard",
  components: { ScanModale, SuccessLevelStudent },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    score: {
      type: Object,
      required: true,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
    proctoringDataProgress: {
      type: Number,
      default: 0,
    },
    hasUnsyncedItems: {
      type: Boolean,
      default: false,
    },
    eventSyncProgress: {
      type: Number,
      default: 0,
    },
    correctionRoute: {
      type: Object,
      required: true,
    },
    proctoringDataSent: {
      type: Boolean,
      default: false,
    },
    calculatingScore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    grade() {
      return new Intl.NumberFormat(this.currentUser.language)
        .format(this.score?.pretty_grade)
    },
    score_100() {
      return this.score.grade / this.quiz.grade_scale * 100
    },
    correctionAvailable() { return this.quiz?.whole_correction_visible },
    showRetake() { return this.quiz?.allow_retake_quiz },

    proctoring() {
      return this.quiz?.video_proctoring
    },
    cardContent() {
      return {
        title: this.title,
        text: this.text,
        showScore: this.quiz?.show_scores && !this.calculatingScore,
        buttons: {
          showCorrection: this.correctionAvailable,
          showRetake: this.showRetake,
          showScanReview: this.quiz.quiz_type === "paper" && !this.quiz.whole_correction_visible,

        },
        items: [
          {
            title: this.$t("live.finalScore.finish.sendingEventAndScreenshots"),
            subtitle: `${this.eventSyncProgress}%`,
            loading: true,
            visible: this.hasUnsyncedItems,
            action: () => this.uploadEventAndScreenshots(),
          },
          {
            title: this.$t("live.finalScore.finish.eventAndScreenshotsSent"),
            success: true,
            visible: !this.hasUnsyncedItems,
          },
          {
            title: this.$t("live.finalScore.finish.text"),
            subtitle: this.$tc(
              "live.answeredOutOf",
              this.quizzesAttemptSummary?.answered_questions_count_cache,
              {
                answers: this.quizzesAttemptSummary?.answered_questions_count_cache,
                questions: this.quiz?.questions_count,
              }),
            success: true,
            visible: true,
          },
          {
            title: this.$t("live.finalScore.calculating.text"),
            subtitle: this.$t("live.finalScore.calculating.reload"),
            action: () => this.fetchScoresByQuiz(this.quiz),
            loading: true,
            visible: this.calculatingScore,
          },
          {
            title: this.$t("live.finalScore.finish.sendingProctoring"),
            subtitle: `${this.proctoringDataProgress}%`,
            loading: true,
            visible: this.proctoring && !this.proctoringDataSent,
            action: () => this.uploadAudio(),
          },
          {
            title: this.$t("live.finalScore.finish.proctoringDataSent"),
            success: true,
            visible: this.proctoring && this.proctoringDataSent,
            action: () => this.uploadAudio(),
          },
        ],
      }
    },
    text() {
      const scoreDetails = `${Math.round(this.score?.correct_answers_count)} ${this.$t("live.outOf")} ${this.quiz?.questions_count}.` + "<br>"
      const gradeNotVisible = this.$t("live.finalScore.finish.gradeNotVisible")  + "<br>"
      const timeoutAnswers = this.$t("live.finalScore.timeout.text") + "<br>"
      const proctoringInfo = "<br>" + this.$t("live.finalScore.finish.proctoring")

      let texts = []
      if(this.quiz?.show_scores) texts.push(scoreDetails)
      if(!this.quiz?.show_scores) texts.push(gradeNotVisible)
      if(this.quiz?.video_proctoring) texts.push(proctoringInfo)
      if(this.quizzesAttemptSummary?.answers_to_review_count > 0) texts.push(timeoutAnswers)

      return texts.join(" ")
    },

    checklist() {
      return this.cardContent.items.filter((item) => item.visible)
    },

  },

  methods: {
    ...mapActions(["fetchScoresByQuiz"]),
    uploadAudio() {
      this.$emit("upload-audio")
    },
    uploadEventAndScreenshots() {
      this.$emit("upload-event-and-screenshots")
    },
  },
}
</script>

<style scoped>

</style>
