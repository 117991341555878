<template>
  <div>
    <router-view />
    <student-chat
      :quiz="quiz"
      v-if="quiz"
    />
    <quiz-screen-record2
      ref="screenRecord"
      :quizzes-attempt-summary="quizzesAttemptSummary"
      :enable-screen-sharing="enableScreenShare"
      :enable-recording="shouldRecordScreen"
      :enable-overlay-detection="enableOverlayDetection"
      @display-surface-changed="handleDisplaySurfaceChange"
      @screen-count-changed="handleScreenCountChange"
      @screen-share-stopped="handleScreenShareStopped"
      @screen-share-started="handleScreenShareStarted"
      @screen-share-system-denied="handleScreenShareSystemDenied"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex"
import ws from "../../web_sockets"
import helpers from "../../helpers"
import api from "../../api"
import quizStudentMixin from "@/views/quizzes/quiz_student_mixin"
import StudentChat from "@/components/quiz/chat/student_chat.vue"
import QuizScreenRecord2 from "@/components/quiz/evalmee/quiz_screen_record2.vue"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"

export default {
  name: "EvalmeeStudentQuiz",
  components: { QuizScreenRecord2, StudentChat },
  mixins: [quizStudentMixin],
  props: {
    quizId: { type: Number, default: null },
    quizzesAttemptSummaryId: { type: Number, default: null },
  },
  data() {
    return {
      enableScreenShare: false,
      enableOverlayDetection: false,
    }
  },
  computed: {
    eventRegisterer() {
      return useStudentEventRegisterer(this.quizId, this.quizzesAttemptSummaryId)
    },
    shouldRecordScreen() {
      return this.eventRegisterer.isOutsideOfExam().value || this.eventRegisterer.hasSuspectBehavior().value
    },
    hasSuspectBehavior() {
      return this.eventRegisterer.hasSuspectBehavior().value
    },
  },
  methods: {
    ...mapActions([
      "fetchQuizzesAttemptSummary",
      "fetchQuiz",
      "newAppNotification",
    ]),
    handleDisplaySurfaceChange(surface) {
      this.eventRegisterer.sharedScreenSurface().value = surface
      this.eventRegisterer.isScreenShared().value = surface !== null
    },
    handleScreenCountChange(count) {
      this.eventRegisterer.screenCount().value = count
    },
    handleScreenShareStopped() {
      this.eventRegisterer.register({ event: "screen_share_stop" })
      this.enableScreenShare = false
      this.eventRegisterer.isScreenShared().value = false
      this.eventRegisterer.sharedScreenSurface().value = null
      this.eventRegisterer.screenCount().value = 0
    },
    handleScreenShareStarted() {
      this.eventRegisterer.register({ event: "screen_share_start" })
    },
    handleScreenShareSystemDenied() {
      this.eventRegisterer.screenSharingError().value = "screen_share_system_denied" 
    },
  },
  watch: {
    quizId: {
      handler() {
        ws.channels
          .StudentsQuiz
          .subscribe({ quizId: this.quizId })
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchQuizzesAttemptSummary(this.quizzesAttemptSummaryId)
      .catch((error) => {
        // if the quiz attempt summary is not found, redirect to the session selection page
        // this can happen if the attempts was deleted by the teacher with a quiz reset when
        // email invitation are already sent
        if(error.response.status === 404) {
          this.$router.push({ name: "sessionsSelection", params: { quizId: this.quizId } })
        }
      })

    this.fetchQuiz(this.quizId)
      .catch(() => {
        this.newAppNotification({
          message: this.$t("live.examOnboarding.quiz.fetchError"),
          type: "error",
          timeout: 10000,
        })
      })

    this.$root.$on("enable-screen-share", () => {
      this.enableScreenShare = true
    })
    this.$root.$on("disable-screen-share", () => {
      this.enableScreenShare = false
    })
    this.$root.$on("enable-overlay-detection", () => {
      this.enableOverlayDetection = true
    })
    this.$root.$on("disable-overlay-detection", () => {
      this.enableOverlayDetection = false
    })
    this.$root.$on("open-screen-share-settings", () => {
      if (this.$refs.screenRecord) {
        this.$refs.screenRecord.startCapture()
      }
    })
  },
  beforeDestroy() {
    // Clean up event listeners
    this.$root.$off("enable-screen-share")
    this.$root.$off("disable-screen-share")
    this.$root.$off("open-screen-share-settings")
    this.$root.$off("enable-overlay-detection")
    this.$root.$off("disable-overlay-detection")
  },
  created() {
    if(helpers.isInIframe()) {
      const path = this.$router.resolve({
        name: "LTIStudent",
        params: { quizId: this.quizId },
      }).href
      const url = `${api.legacy.evalmeeStudentUrl()}/${path}`
      window.self.location = url
    }
  },
}
</script>

