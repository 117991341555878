<template>
  <div
    @click="small = !small"
    v-if="showCamera"
  >
    <v-card
      :class="cameraClass"
      class="transparent-on-hover"
    >
      <v-btn
        v-if="!small"
        fab
        class="close_button"
      >
        <v-icon>
          close_fullscreen
        </v-icon>
      </v-btn>

      <vue-web-cam
        class="camera-preview"
        ref="webcam"
        :device-id="currentUser.camera_id"
        width="100%"
        height="auto"
        @cameras="onCameras"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @video-live="startProctoringLoop"
      />

      <recording-indicator
        v-if="!small"
        :recording="isProctoringActive"
        class="record_status"
      />

      <mic-vu-meter class="vu_meter" />

      <quiz-screen-record
        v-if="quiz.screen_proctoring"
        open-stream
        :is-proctoring-active="isProctoringActive"
        :user="currentUser"
        :quiz="quiz"
      />

      <audio-recorder
        :is-proctoring-active="isProctoringActive"
        :user="currentUser"
        :quiz="quiz"
        v-if="quiz.audio_proctoring"
      />
    </v-card>
  </div>
</template>

<script>
import { WebCam } from "vue-web-cam"
import { mapActions, mapGetters } from "vuex"
import proctoringUploader from "../../../helpers/proctoring_uploader"
import RecordingIndicator from "./recording_indicator.vue"
import MicVuMeter from "./mic_vu_meter.vue"
import quizStudentMixin from "../../../views/quizzes/quiz_student_mixin"
import QuizScreenRecord from "./quiz_screen_record.vue"
import AudioRecorder from "../../../views/evalmee_student/audioRecorder.vue"

export default {
  name: "QuizCamera",
  components: {
    AudioRecorder,
    QuizScreenRecord,
    MicVuMeter,
    RecordingIndicator,
    "vue-web-cam": WebCam,
  },
  mixins: [quizStudentMixin],
  data() {
    return {
      camera: null,
      deviceId: null,
      devices: [],
      img: [],
      small: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "showCamera", "proctoring"]),
    folderName() {
      return proctoringUploader.folderName({
        quizId: this.quiz.id,
        userId: this.currentUser.id,
      })
    },
    isProctoringActive() { return this.proctoring },
    cameraFrameInterval() {
      return parseInt(this.quiz?.proctoring_frame_interval) || 1000
    },
    cameraClass() {
      return this.small ? "small_camera" : "big_camera"
    },
  },
  watch: {
    // camera: {
    //   handler(id) {
    //     this.deviceId = id;
    //   },
    //   immediate: true
    // },
    // currentUser: {
    //   handler() {
    //     this.deviceId = this.currentUser.camera_id;
    //   },
    //   immediate: true
    // },
    devices: {
      handler() {
        // Once we have a list select the first one
        // console.log(this.devices)
        const [first, ...tail] = this.devices
        if (first) {
          this.camera = this.currentUser.camera_id
          this.deviceId = this.currentUser.camera_id
          this.start()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateCurrentUser"]),
    onCameras(cameras) {
      this.devices = cameras
      // console.log("On Cameras Event Preview Cam", cameras)
    },
    onStarted(stream) {
      // console.log("On Started Event", stream)
    },
    onStopped(stream) {
      // console.log("On Stopped Event", stream)
    },
    capture: function () {
      this.img = this.$refs.webcam.capture()
      this.s3upload()
      // console.log("img !")
    },
    onError(error) {
      // console.log("On Error Event", error)
    },
    s3upload() {
      const uploader = proctoringUploader.setup()
      proctoringUploader.upload({
        uploader,
        imgBase64: this.img,
        path: this.folderName + "/images",
      })
    },
    start() { this.$refs.webcam.start() },
    stop() { this.$refs.webcam?.stop() },
    startProctoringLoop() {
      this.timer = setInterval(() => {
        if(this.isProctoringActive) this.capture()
      },this.cameraFrameInterval)
    },
    stopProctoringLoop() {
      clearInterval(this.timer)
    },

  },
  beforeDestroy() {
    this.stop()
    this.stopProctoringLoop()
  },
}

</script>

<style scoped>
  .img-responsive{
    width: 100%;
  }

  .camera-preview {
    display: block;
  }

  .record_status{
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 84%;
  }

  .transparent-on-hover:hover .close_button{
    visibility: visible;
  }
  .close_button{
    position: absolute;
    top: 4px;
    right: 4px;
    visibility: hidden;
  }

  .transparent-on-hover:hover {
    /*opacity: 20%;*/
  }

  .small_camera {
    max-width: 40px;
    min-width: 40px;
    width: 15vw;
  }

  .big_camera {
    max-width: 300px;
    min-width: 150px;
    width: 15vw;
  }

  .vu_meter {
    position: absolute;
    bottom: 4px;
    right: 4px;
    height: 100%;
    display: block;
  }
</style>
